import * as Yup from 'yup';
import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';

// material
import { Stack, TextField, Autocomplete, Typography, Container, Card } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import Page from '../components/Page';
import OrgOptions from '../components/OrgOptions';
import API_URL from '../global';
import Breadcrumbs from '../components/Breadcrumbs';

// ----------------------------------------------------------------------

export default function ConfigurationPage() {
  const navigate = useNavigate();
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [fileCabinet, setFileCabinet] = useState(false);
  const [cabinetList, setCabinatList] = useState([]);
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const companyId = localStorage.getItem('userCompanyId');

  const fetchFileCabinate = (organizationId, userId) => {
    axios
      .get(`${API_URL}/docuware/api/get-filecabinates/?organization_id=${organizationId}&company_id=${companyId}`, {
        headers: {
          Authorization: `Token  ${localStorage.getItem('token')}`,
        },
      })
      .then((response) => {
        setIsLoading(false);
        prepareCabinatList(response.data.FileCabinet);
      })
      .catch((err) =>{
         console.log(err)
        setIsLoading(false);
        });
  };

  const prepareCabinatList = (data) => {
    const cabinateOptions = data
      ? data.map((item) => {
          const cabinateItem = {};
          cabinateItem.id = item.Id;
          cabinateItem.label = item.Name;
          return cabinateItem;
        })
      : [];
    setCabinatList(cabinateOptions);
  };

  const ConfigFormSchema = Yup.object().shape({
    organization: Yup.string().required('Organization is required'),
    fileCabinet: Yup.string().required('File Cabinate is required'),
  });

  const formik = useFormik({
    initialValues: {
      organization: '',
      fileCabinet: '',
    },
    validationSchema: ConfigFormSchema,
    onSubmit: () => {
      navigate('/app/sync-bill', { replace: false });
    },
  });

  const { handleSubmit, getFieldProps, setFieldValue } = formik;

  const handleNext = () => {
    const fileCabinet = getFieldProps('fileCabinet');
    const selectedOrg = getFieldProps('organization');

    if (selectedOrg.value && fileCabinet.value) {
      navigate('/app/bill-maping', { replace: false });
    } else {
      let error = { selectOrg: '', fileCabinet: '' };
      // eslint-disable-next-line no-unused-expressions
      fileCabinet.value ? null : (error = { ...error, fileCabinet: 'File Cabinet is required' });
      // eslint-disable-next-line no-unused-expressions
      selectedOrg.value ? null : (error = { ...error, selectOrg: 'Organization is required' });
      setError(error);
    }
  };
  const navigates = [
    { path: '/quickbooks/quickbooks_code/', name: 'Configuration' },
    { path: '', name: 'DocuWare Configuration' },
  ];

  return (
    <Page title="DocuSync: Configuration">
      <Card variant="outlined" sx={{ mt: 0 }} style={{ overflow: 'visible', borderRadius: 5 }}>
        <Breadcrumbs separator=">" navigates={navigates} />
        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" sx={{ mb: 10, mt: 6 }}>
          <Container style={{ maxWidth: '100%' }}>
            <Typography variant="h5" sx={{ mb: 3 }}>
              DocuWare Configuration
            </Typography>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack style={{ marginTop: 20, width: 500 }} spacing={2}>
                  <OrgOptions
                    placeHolderLabel="Select Organization"
                    size="small"
                    error={error.selectOrg}
                    {...getFieldProps('organization')}
                    onChange={(event, value) => {
                      if (value !== undefined && value !== null) {
                        setFieldValue('organization', value);
                        setError({ ...error, selectOrg: '' });
                        const organizationId = value.id;
                        const userId = localStorage.getItem('userId');
                        setIsLoading(true);
                        fetchFileCabinate(organizationId, userId);
                        localStorage.setItem('organizationId', organizationId);
                      } else {
                        setFieldValue('organization', null);
                        setFieldValue('fileCabinet', null);
                        setCabinatList([]);
                      }
                    }}
                  />
                  {error.selectOrg && <span style={{ color: '#D43F3E', fontSize: 10 }}>{error.selectOrg}</span>}
                  <Autocomplete
                    loading={isLoading}
                    disablePortal
                    id="combo-box-demo"
                    options={cabinetList}
                    disabled={false}
                    size={'small'}
                    value={null}
                    style={{ border: error.fileCabinet ? '1px solid #D43F3E' : '', borderRadius: 6 }}
                    {...getFieldProps('fileCabinet')}
                    defaultValue={null}
                    onChange={(event, value) => {
                      setFieldValue('fileCabinet', value);
                      setError({ ...error, fileCabinet: '' });
                      const fileCabinetId = value !== undefined ? value.id : null;
                      setFileCabinet(fileCabinetId);
                      localStorage.setItem('fileCabinetId', fileCabinetId);
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) => <TextField {...params} label={'File Cabinet'} />}
                  />
                  {error.fileCabinet && <span style={{ color: '#D43F3E', fontSize: 10 }}>{error.fileCabinet}</span>}
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={handleNext}
                    style={{ maxWidth: '100px', minWidth: '100px', maxHeight: '40px', minHeight: '40px' }}
                    loading={isBtnLoading}
                  >
                    Next
                  </LoadingButton>
                </Stack>
              </Form>
            </FormikProvider>
          </Container>
        </Stack>
      </Card>
    </Page>
  );
}
