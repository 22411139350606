// material
import { useEffect, useState } from 'react';
// components
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import API_URL from '../global';

export default function OrgOptions(props) {
  const [orgList, setOrgList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const prepareOrgOptions = (values) => {
    const orgSelections = values.map((orgObject) => {
      const orgItem = {};
      orgItem.id = orgObject.Id;
      orgItem.label = orgObject.Name;
      return orgItem;
    });
    setOrgList(orgSelections);
  };

  const refreshOrgs = () => {
    const companyId = localStorage.getItem('userCompanyId');
    axios
      .get(`${API_URL}/docuware/api/get-organizations/?company_id=${companyId}`, {
        headers: {
          Authorization: `Token  ${localStorage.getItem('token')}`,
        },
      })
      .then((response) => {
        const organization = response.data.Organization;
        setIsLoading(false);
        if (organization !== undefined && organization !== null) {
          prepareOrgOptions(organization);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    refreshOrgs();
  }, []);

  return (
    <Autocomplete
      loading={isLoading}
      disablePortal
      id="combo-box-demo"
      options={props.options && props.options !== null ? props.options : orgList}
      disabled={props.disabled || false}
      size={props.size || 'large'}
      style={{ border: props.error ? '1px solid #D43F3E' : '', borderRadius: 6 }}
      value={props.value || null}
      hidden={props.hidden}
      defaultValue={props.defaultValue || null}
      onChange={props.onChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => <TextField {...params} label={props.placeHolderLabel} />}
    />
  );
}
