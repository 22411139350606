import * as Yup from 'yup';
import { Helmet } from 'react-helmet-async';
import filter from 'lodash/filter';
import { sentenceCase } from 'change-case';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
// @mui
import CircularProgress from '@mui/material/CircularProgress';
import {
  Card,
  CardHeader,
  Table,
  Stack,
  Paper,
  Grid,
  Popover,
  Autocomplete,
  TableRow,
  Tooltip,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  Checkbox,
  TextField,
  IconButton,
  Box,
  TableContainer,
  TablePagination,
  Button,
} from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { styled } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import LinearProgress from '@mui/material/LinearProgress';
import { useFormik, Form, FormikProvider } from 'formik';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { ListHeads } from '../sections/@dashboard/user';
import { fetchDocuments, markTransactionsAsIgnored, getBillConfigList } from '../services/api';
import DocuwareIcon from '../components/DocuwareIcon';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: '', label: '', alignRight: false },
  { id: 'billDate', label: 'Bill Date', alignRight: false },
  { id: 'vendorName', label: 'Vendor Name', alignRight: false },
  { id: 'amount', label: 'Amount', alignRight: false },
  { id: 'txnStatus', label: 'Sync Status', alignRight: false },
  { id: 'remarks', label: 'Remarks', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

const SmallDateRangePicker = styled(DatePicker)(() => ({
  '& .MuiIconButton-root': {
    padding: '4px',
    height: '12px',
  },
  '& .MuiIconButton-root .MuiSvgIcon-root': {
    fontSize: '17px',
  },
  '& .MuiInputBase-input': {
    padding: '14px',
    fontSize: '10pt',
    height: '12px',
    width: '135px',
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function IgnoredTransactions() {
  useEffect(() => {
    const userId = localStorage.getItem('userId');
    const qbCompanyId = JSON.parse(localStorage.getItem('qbCompany')).id;
    const companyId = localStorage.getItem('userCompanyId');
    getBillConfigList(userId, companyId).then((response) => {
      prepareConfigList(response.data);
    });
    const cacheTransactionList = localStorage.getItem('cacheTransactionList');
    if (cacheTransactionList) {
      setTransactionList(JSON.parse(cacheTransactionList));
      setIsShowTable(true);
    }
    const cacheSyncConfiguration = localStorage.getItem('cacheSyncConfiguration');
    if (cacheSyncConfiguration) {
      const jsonData = JSON.parse(cacheSyncConfiguration);
      const { id } = jsonData;
      const { label } = jsonData;
      setConfig({ id, label });
      refreshData({ id: jsonData.id, label: jsonData.label });
    }

    const cacheConfigFileCabinetId = localStorage.getItem('cacheConfigFileCabinetId');
    if (cacheConfigFileCabinetId) {
      setFileCabinetId(cacheConfigFileCabinetId);
    }
    setIsShowValidBillCount(false);
  }, []);

  const prepareConfigList = (data) => {
    const configOptions = data
      ? data.map((item) => {
          const configItem = {};
          configItem.id = item.id;
          configItem.label = item.name;
          return configItem;
        })
      : [];
    setConfigList(configOptions);
  };

  const navigate = useNavigate();
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isShowTable, setIsShowTable] = useState(true);
  const [configList, setConfigList] = useState(false);
  const [config, setConfig] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [transactionList, setTransactionList] = useState([]);
  const [importableBillCount, setImportableBillCount] = useState(false);
  const [totalBillCount, setTotalBillCount] = useState(false);
  const [isShowValidBillCount, setIsShowValidBillCount] = useState(false);
  const [fileCabinetId, setFileCabinetId] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isRetrying, setIsRetrying] = useState(false);

  const currentDate = new Date();
  const thirtyDaysAgo = new Date(currentDate);
  thirtyDaysAgo.setDate(currentDate.getDate() - 30);

  const endDay = `0${currentDate.getDate()}`.slice(-2);
  const endMonth = currentDate.getMonth() + 1; // (January gives 0)
  const endYear = currentDate.getFullYear();

  const startDay = `0${thirtyDaysAgo.getDate()}`.slice(-2);
  const startMonth = `0${thirtyDaysAgo.getMonth() + 1}`.slice(-2);
  const startYear = thirtyDaysAgo.getFullYear();

  const [startDate, setStartDate] = useState(dayjs(`${startMonth}/${startDay}/${startYear}`, 'MM/DD/YYYY'));
  const [startDateStr, setStartDateStr] = useState(`${startMonth}/${startDay}/${startYear}`);
  const [endDate, setEndDate] = useState(dayjs(`${endMonth}/${endDay}/${endYear}`, 'MM/DD/YYYY'));
  const [endDateStr, setEndDateStr] = useState(`${endMonth}/${endDay}/${endYear}`);

  const handleCheckboxChange = (id) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
    localStorage.setItem('cacheSyncPageNo', newPage);
  };

  const prepareTransactionList = (transactions) =>
    transactions.map((txn) => ({
      id: txn.docuware_document_id,
      billId: txn.id,
      billNumber: txn.bill_no,
      accountPay: txn.acc_pay,
      vendorName: txn.vendor_name && txn.vendor_name !== 'False' ? txn.vendor_name : null,
      dwVendorName: txn.docuware_vendor_name && txn.docuware_vendor_name !== 'False' ? txn.docuware_vendor_name : null,
      terms: txn.terms,
      billDate: txn.bill_date,
      dueDate: txn.due_date,
      privateNote: txn.private_note,
      locationName: txn.location_name,
      categoryName: txn.category_name,
      itemName: txn.item_name,
      amount: txn.bill_amount,
      txnStatus: txn.is_valid_bill ? 'Not Synced' : 'Sync Error',
      remarks: txn.validation_remark,
      isValidBill: txn.is_valid_bill,
      qbBillLink: '',
      lineItems: txn.line_items ? txn.line_items : [],
    }));

  const refreshData = (configValue) => {
    setConfig(configValue);
    setIsTableLoading(true);
    setIsShowTable(true);
    fetchDocuments(configValue.id, false).then((response) => {
      if (response.status === 200) {
        const docList = response.data.documents ? response.data.documents : [];
        const configFileCabinetId = response.data.file_cabinet_id ? response.data.file_cabinet_id : false;
        const noOfValidImportableBills = response.data.valid_bill_count ? response.data.valid_bill_count : false;
        const noOfTotalBills = response.data.total_bill_count ? response.data.total_bill_count : false;
        setTotalBillCount(noOfTotalBills);
        setIsShowValidBillCount(true);
        setImportableBillCount(noOfValidImportableBills);
        setFileCabinetId(configFileCabinetId);
        const preparedTxn = prepareTransactionList(docList);
        setTransactionList(preparedTxn);
        localStorage.setItem('cacheConfigFileCabinetId', configFileCabinetId);
        localStorage.setItem('cacheTransactionList', JSON.stringify(preparedTxn));
        localStorage.setItem('cacheSyncConfiguration', JSON.stringify(configValue));
        setIsTableLoading(false);
        setIsShowTable(true);
      }
    });
  };

  const handleBillRowClick = (billId) => {
    if (billId) {
      navigate(`/app/bill-info/${billId}`);
    } else {
      alert('Error');
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - transactionList.length) : 0;
  const LoginSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
  });
  const filteredTxns = applySortFilter(transactionList, getComparator(order, orderBy), filterName);
  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      console.log('Clicked');
      navigate('/app/bill-maping', { replace: false });
    },
  });
  const isNotFound = !filteredTxns.length && !!filterName;
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
    return date.toLocaleString(undefined, options).replace(/(\d+)\/(\d+)\/(\d+)/, '$1/$2/$3');
  };
  const { handleSubmit } = formik;

  return (
    <>
      <Helmet>
        <title> Ignored Transactions </title>
      </Helmet>
      <Container style={{ width: '100%', maxWidth: '100%' }}>
        {configList ? (
          <Card style={{ overflow: 'visible', width: '100%', borderRadius: 5 }}>
            <CardHeader title="Ignored Transactions" />
            <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" sx={{ mb: 1, ml: 3 }}>
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <Stack style={{ marginTop: 20, marginBottom: 10, width: 900 }} spacing={2}>
                    <Grid container spacing={2}>
                      <Grid item sx={6} md={5}>
                        <div style={{ marginTop: 10 }}>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={configList}
                            disabled={isTableLoading}
                            size={'small'}
                            value={config || null}
                            defaultValue={null}
                            onChange={(event, newValue) => {
                              refreshData(newValue);
                              localStorage.setItem('configId', newValue.id);
                            }}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            renderInput={(params) => <TextField {...params} label={'Select Configuration'} />}
                          />
                        </div>
                      </Grid>
                      <Grid item sx={3} md={3}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={['SmallDateRangePicker']}>
                            <SmallDateRangePicker
                              label="Start"
                              value={startDate || null}
                              onChange={(e) => {
                                const jsDate = e.toDate();
                                const day = jsDate.getDate();
                                const month = jsDate.getMonth() + 1;
                                const year = jsDate.getFullYear();
                                setStartDate(e);
                                setStartDateStr(`${month}/${day}/${year}`);
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </Grid>
                      <Grid item sx={3} md={3}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={['SmallDateRangePicker']}>
                            <SmallDateRangePicker
                              label="End"
                              value={endDate || null}
                              onChange={(e) => {
                                const jsDate = e.toDate();
                                const day = jsDate.getDate();
                                const month = jsDate.getMonth() + 1;
                                const year = jsDate.getFullYear();
                                setEndDate(e);
                                setEndDateStr(`${month}/${day}/${year}`);
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </Stack>
                </Form>
              </FormikProvider>
            </Stack>
            <Scrollbar>
              <Stack style={{ marginBottom: 10, width: 800 }} spacing={1.5}>
                <div style={{ display: 'flex', marginLeft: 2 }}>
                  {Array.isArray(selectedRows) && selectedRows.length > 0 && (
                    <Grid item sx={1.5} md={1.5} style={{ marginLeft: 5 }}>
                      <Button
                        style={{ marginTop: 15 }}
                        size="small"
                        color="inherit"
                        variant="outlined"
                        sx={{ borderColor: 'grey' }}
                        onClick={() => {
                          setIsTableLoading(true);
                          markTransactionsAsIgnored(config.id, selectedRows).then((res) => {
                            setIsTableLoading(false);
                            const cacheSyncConfiguration = localStorage.getItem('cacheSyncConfiguration');
                            const configVals = JSON.parse(cacheSyncConfiguration);
                            refreshData({ id: configVals.id, label: configVals.label });
                            setSelectedRows([]);
                          });
                        }}
                      >
                        <Icon style={{ color: '#D43F3E', height: '20px', width: '18px' }} icon="flat-color-icons:cancel" />
                        <span style={{ fontSize: 15, marginLeft: 5 }}>Un Ignore</span>
                      </Button>
                    </Grid>
                  )}
                </div>
              </Stack>
              {isShowTable && (
                <TableContainer sx={{ width: '100%', minWidth: 800 }}>
                  {isRetrying && <LinearProgress />}
                  {isTableLoading && <LinearProgress />}
                  <Table>
                    <ListHeads
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={transactionList.length}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      {filteredTxns.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        const {
                          id,
                          billId,
                          billDate,
                          vendorName,
                          dwVendorName,
                          amount,
                          txnStatus,
                          remarks,
                          qbBillLink,
                          isValidBill,
                        } = row;
                        return (
                          <TableRow style={{ height: 4 }} hover key={id} tabIndex={-1} role="checkbox">
                            <TableCell align="left" style={{ paddingTop: 5, paddingBottom: 5 }}>
                              <Checkbox
                                checked={selectedRows.includes(id)}
                                onClick={(e) => e.stopPropagation()}
                                onChange={() => {
                                  handleCheckboxChange(id);
                                }}
                              />
                            </TableCell>
                            <TableCell align="left" style={{ paddingTop: 5, paddingBottom: 5 }}>
                              <span
                                style={{ textDecoration: 'underline', color: 'darkblue', cursor: 'pointer' }}
                                onClick={() => {
                                  handleBillRowClick(billId);
                                }}
                              >
                                {billDate ? formatDate(billDate) : ''}
                              </span>
                            </TableCell>

                            <TableCell align="left" style={{ paddingTop: 5, paddingBottom: 5 }}>
                              {vendorName !== null || undefined ? (
                                <>
                                  {vendorName}
                                  <br />
                                  <Label style={{ height: 16, fontSize: 9 }} color={'success'}>
                                    {sentenceCase('qb vendor')}
                                  </Label>
                                </>
                              ) : (
                                <>
                                  {dwVendorName}
                                  <br />
                                  <Label style={{ height: 16, fontSize: 9 }} color={'primary'}>
                                    {sentenceCase('dw vendor')}
                                  </Label>
                                </>
                              )}
                            </TableCell>

                            <TableCell align="right" style={{ paddingTop: 5, paddingBottom: 5 }}>
                              <Typography variant="subtitle2" style={{ fontSize: 12 }}>
                                {amount ? Number(amount).toLocaleString(undefined, { minimumFractionDigits: 2 }) : ''}
                              </Typography>
                            </TableCell>

                            <TableCell align="left" style={{ paddingTop: 5, paddingBottom: 5 }}>
                              {isValidBill && txnStatus === 'Not Synced' && (
                                <Label color={'default'}>{sentenceCase('Ready To Sync')}</Label>
                              )}
                              {txnStatus === 'Sync Error' && <Label color={'error'}>{sentenceCase(txnStatus)}</Label>}
                              {txnStatus === 'Synced' && <Label color={'success'}>{sentenceCase(txnStatus)}</Label>}
                            </TableCell>

                            <TableCell align="left" style={{ paddingTop: 5, paddingBottom: 5 }}>
                              <Typography variant="subtitle2" style={{ marginTop: 2, fontSize: 10 }}>
                                {remarks}
                                <br />
                                {!isValidBill && (
                                  <Label style={{ height: 16, fontSize: 9 }} color={'default'}>
                                    {sentenceCase('dw remark')}
                                  </Label>
                                )}
                                {remarks.startsWith('Business Validation Error') &&
                                  (txnStatus === 'Sync Error' || txnStatus === 'Not Synced') && (
                                    <Label style={{ height: 16, fontSize: 9 }} color={'default'}>
                                      {sentenceCase('qb remark')}
                                    </Label>
                                  )}
                              </Typography>
                            </TableCell>
                            {txnStatus === 'Synced' && (
                              <TableCell align="left" style={{ paddingTop: 5, paddingBottom: 5 }}>
                                <IconButton size="large" color="inherit" onClick={() => {}}>
                                  <Icon
                                    style={{ color: 'green', height: '20px', width: '18px' }}
                                    icon={txnStatus === 'Synced' ? 'mdi:refresh' : ''}
                                  />
                                </IconButton>
                              </TableCell>
                            )}
                            <TableCell align="left" style={{ paddingTop: 5, paddingBottom: 5 }}>
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {qbBillLink && (
                                  <Tooltip title={'Click to View Bill in QuickBooks'} placement="top-end" arrow>
                                    <IconButton
                                      size="large"
                                      color="inherit"
                                      component="a"
                                      href={qbBillLink}
                                      target="_blank"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                      }}
                                    >
                                      <Iconify
                                        icon={'octicon:link-16'}
                                        style={{ color: 'green', height: '20px', width: '20px' }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                )}

                                <IconButton
                                  size="large"
                                  color="inherit"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    if (fileCabinetId) {
                                      const url = `https://inceptiontech.docuware.cloud/DocuWare/Platform/WebClient/Integration?p=V&did=${id}&fc=${fileCabinetId}`;
                                      window.open(url);
                                    } else {
                                      alert('Can not find file cabinet from Configuration');
                                    }
                                  }}
                                >
                                  <DocuwareIcon style={{ width: '24px', height: '24px' }} />
                                </IconButton>
                              </Box>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isNotFound && !isTableLoading && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper
                              sx={{
                                textAlign: 'center',
                              }}
                            >
                              <Typography variant="h6" paragraph>
                                Not found
                              </Typography>

                              <Typography variant="body2">
                                No results found for &nbsp;
                                <strong>&quot;{filterName}&quot;</strong>.
                                <br /> Try checking for typos or using complete words.
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              )}
              {!isTableLoading && isShowTable && (
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={transactionList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </Scrollbar>
          </Card>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress style={{ margin: 10 }} />
          </div>
        )}
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}
