import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { filter } from 'lodash';

import {
  Stack,
  Paper,
  Typography,
  Container,
  Grid,
  Card,
  CardActions,
  Table,
  Button,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Pagination,
  Switch,
  Tooltip,
  Modal,
  Fade,
} from '@mui/material';
import { Icon } from '@iconify/react';

import LinearProgress from '@mui/material/LinearProgress';
import { LoadingButton } from '@mui/lab';

import SnackbarToast from '../components/Snackbar';
import { ListHeads } from '../sections/@dashboard/user';
import Scrollbar from '../components/scrollbar';
import Page from '../components/Page';
import { changeFeatureFlag, getCompanyList, syncQBClass, syncQBGl, syncQBVendor } from '../services/api';

const TABLE_HEAD = [
  { id: 'companyName', label: 'Company', alignRight: false },
  { id: 'listButtons', label: '', alignRight: false },
  { id: 'poEnable', label: 'IsPOEnable', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function BillConfigList() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [paginationDetails, setPaginationDetails] = useState({});
  const [poEnabledCompanies, setPoEnabledCompanies] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [responseMessage, setResponseMessage] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');

  useEffect(() => {
    setIsTableLoading(true);
    getCompanyList(page).then((res) => {
      if (res.data && res.data !== undefined) {
        const companyList = prepareCompanyList(res.data.results);
        setPaginationDetails({count: Math.ceil(res.data.count/10), next: res.data.next, previous: res.data.previous})
        setCompanyList(companyList);
        setIsTableLoading(false);
      }
    });
  }, [poEnabledCompanies, page]);

  useEffect(() => {
    setIsTableLoading(true);
    getCompanyList(page).then((res) => {
      if (res.data && res.data !== undefined) {
        const companyList = prepareCompanyList(res.data.results);
        setPaginationDetails({count: Math.ceil(res.data.count/10), next: res.data.next, previous: res.data.previous})
        const newData = [];
        companyList.forEach((i) => {
          if (i.isPoEnable) {
            newData.push(i.id);
          }
        });
        setPoEnabledCompanies(newData);
        setCompanyList(companyList);
        setIsTableLoading(false);
      }
    });
  }, []);

  const filteredConfigList = applySortFilter(companyList, getComparator(order, orderBy), '');
  const isNotFound = !filteredConfigList.length;

  const prepareCompanyList = (data) => {
    const configOptions = data
      ? data.map((item) => {
          const configItem = {};
          configItem.id = item.id;
          configItem.name = item.name;
          configItem.isPoEnable = item.is_po_enable;
          return configItem;
        })
      : [];
    return configOptions;
  };

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };
  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - companyList.length) : 0;

  const handleEnablePO = (id, isPoEnable) => {
    changeFeatureFlag({ companyId: id, featureName: 'Purchase Order', isEnable: isPoEnable })
      .then((response) => {
        const message = response.data.status || response.data.message;
        setOpenAlert(true);
        setResponseMessage({ message, type: response.data.status ? 'success' : 'error', vertical: 'top' });
        if (response.data.status === 'Success') {
          if (poEnabledCompanies.includes(id)) {
            const data = [...poEnabledCompanies];
            const index = data.indexOf(id);
            data.splice(index, 1);
            setPoEnabledCompanies(data);
          } else {
            const data = [...poEnabledCompanies, id];
            setPoEnabledCompanies(data);
          }
        } else {
          const data = [...poEnabledCompanies];
          setPoEnabledCompanies(data);
        }
        window.location.reload();
      })
      .catch((error) => {
        console.log(error, 'error');
      });
  };

  return (
    <Page title="DocuSync To QB">
      <SnackbarToast
        open={openAlert}
        handleClose={() => setOpenAlert(false)}
        message={responseMessage.message}
        type={responseMessage.type}
        vertical={responseMessage.vertical}
      />
      <Card variant="outlined" sx={{ mt: 0 }} style={{ borderRadius: 5 }}>
        <CardActions style={{ justifyContent: 'space-between', marginLeft: 5 }}>
          <Typography variant="h5">Companies</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              navigate('/app/company-setup', { replace: false });
            }}
          >
            + Create Company
          </Button>
        </CardActions>
        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" sx={{ mb: 1, mt: 1, ml: -3, mr: -3 }}>
          <Container style={{ maxWidth: '100%' }}>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <ListHeads
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={companyList.length}
                    onRequestSort={handleRequestSort}
                  />
                  {isTableLoading ? (
                    <TableRow>
                      <TableCell align="center" colSpan={7}>
                        <LinearProgress />
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableBody>
                      {filteredConfigList.map((row, i) => {
                        const { id, name, isPoEnable } = row;

                        return (
                          <TableRow
                            style={{ height: 3, overflow: 'visible' }}
                            hover
                            key={name}
                            tabIndex={-1}
                            role="checkbox"
                          >
                            <TableCell component="th" scope="row" style={{ width: '15%' }}>
                              <Typography variant="subtitle2" noWrap>
                                <Grid item sx={6} md={6}>
                                  {name}
                                </Grid>
                              </Typography>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                style={{ marginTop: 15, marginLeft: 10 }}
                                size="small"
                                color="inherit"
                                variant="outlined"
                                sx={{ borderColor: 'grey' }}
                                onClick={() => {
                                  navigate(`/app/company-rights/${id}`, { replace: false });
                                  localStorage.setItem('manageCompany', name);
                                }}
                              >
                                <Icon style={{ color: '#2E3192', height: '20px', width: '18px' }} icon="ri:user-line" />
                                <span style={{ fontSize: 15, marginLeft: 5 }}>Manage Company</span>
                              </Button>
                              <Tooltip title="Sync Gls in QuickBooks" placement="top" arrow>
                                <Button
                                  style={{ marginTop: 15, marginLeft: 30 }}
                                  size="small"
                                  color="inherit"
                                  variant="outlined"
                                  sx={{ borderColor: 'grey' }}
                                  onClick={() => {
                                    setSelectedOption({ field: 'gl', value: id });
                                    setOpenModal(true);
                                  }}
                                >
                                  <Icon
                                    style={{ color: 'green', height: '20px', width: '18px' }}
                                    icon="octicon:sync-16"
                                  />
                                  <span style={{ fontSize: 15, marginLeft: 5 }}>GLs</span>
                                </Button>
                              </Tooltip>
                              <Tooltip title="Sync Classes in QuickBooks" placement="top" arrow>
                                <Button
                                  style={{ marginTop: 15, marginLeft: 10 }}
                                  size="small"
                                  color="inherit"
                                  variant="outlined"
                                  sx={{ borderColor: 'grey' }}
                                  onClick={() => {
                                    setSelectedOption({ field: 'classes', value: id });
                                    setOpenModal(true);
                                  }}
                                >
                                  <Icon
                                    style={{ color: 'green', height: '20px', width: '18px' }}
                                    icon="octicon:sync-16"
                                  />
                                  <span style={{ fontSize: 15, marginLeft: 5 }}>Classes</span>
                                </Button>
                              </Tooltip>
                              <Tooltip title="Sync Vendors in QuickBooks" placement="top" arrow>
                                <Button
                                  style={{ marginTop: 15, marginLeft: 10 }}
                                  size="small"
                                  color="inherit"
                                  variant="outlined"
                                  sx={{ borderColor: 'grey' }}
                                  onClick={() => {
                                    setSelectedOption({ field: 'vendors', value: id });
                                    setOpenModal(true);
                                  }}
                                >
                                  <Icon
                                    style={{ color: 'green', height: '20px', width: '18px' }}
                                    icon="octicon:sync-16"
                                  />
                                  <span style={{ fontSize: 15, marginLeft: 5 }}>Vendors</span>
                                </Button>
                              </Tooltip>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Switch
                                checked={isPoEnable || poEnabledCompanies.find((i) => i === id)}
                                onChange={() => handleEnablePO(id, !isPoEnable)}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {/* {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )} */}
                    </TableBody>
                  )}

                  {isNotFound && !isTableLoading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              {!isTableLoading && (
                <Pagination count={paginationDetails.count} page={page} onChange={handleChangePage} />
              )}
            </Scrollbar>
          </Container>
        </Stack>
      </Card>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={() => setOpenModal(false)}
        closeAfterTransition
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Fade in={openModal}>
          <Card variant="outlined" sx={{ mt: 0, width: '40%' }} style={{ borderRadius: 5 }}>
            <div style={{ display: 'flex', margin: 20, justifyContent: 'center' }}>
              Do you want to Sync QuickBooks{' '}
              {`${selectedOption.field === 'gl' ? 'Gls' : selectedOption.field === 'classes' ? 'Classes' : 'Vendors'}`}{' '}
              ?
            </div>
            <div style={{ display: 'flex' }}>
              <LoadingButton
                onClick={() => {
                  setOpenModal(false);
                  if (selectedOption.field === 'gl') {
                    setOpenAlert(true);
                    setResponseMessage({
                      message: 'GLs sync started, will be done in few minutes.',
                      type: 'warning',
                      vertical: 'bpttom',
                    });
                    // alert('GL sync started, will be synced in few Minutes.');
                    syncQBGl(selectedOption.value).then((res) => {
                      if (res.data && res.data !== undefined) {
                        console.log('--GL Synced--');
                      }
                    });
                  } else if (selectedOption.field === 'classes') {
                    setOpenAlert(true);
                    setResponseMessage({
                      message: 'Classes sync started, will be done in few minutes.',
                      type: 'warning',
                      vertical: 'bpttom',
                    });
                    // alert('Classes sync started, will be synced in few Minutes.');
                    syncQBClass(selectedOption.value).then((res) => {
                      if (res.data && res.data !== undefined) {
                        console.log('--Class Synced--');
                      }
                    });
                  } else {
                    setOpenAlert(true);
                    setResponseMessage({
                      message: 'Vendors sync started, will be done in few minutes.',
                      type: 'warning',
                      vertical: 'bpttom',
                    });
                    // alert('Vendors sync started, will be synced in few Minutes.');
                    syncQBVendor(selectedOption.value).then((res) => {
                      if (res.data && res.data !== undefined) {
                        console.log('--Vendors Synced--');
                      }
                    });
                  }
                }}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                style={{
                  maxWidth: '100px',
                  minWidth: '100px',
                  maxHeight: '40px',
                  minHeight: '40px',
                  marginBottom: 20,
                  marginTop: 10,
                  marginLeft: '25%',
                }}
                loading={false}
              >
                Ok
              </LoadingButton>
              <LoadingButton
                onClick={() => {
                  setOpenModal(false);
                }}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                style={{
                  backgroundColor: '#D43F3E',
                  maxWidth: '100px',
                  minWidth: '100px',
                  maxHeight: '40px',
                  minHeight: '40px',
                  marginBottom: 20,
                  marginLeft: '10%',
                  marginTop: 10,
                }}
                loading={false}
              >
                Cancel
              </LoadingButton>
            </div>
          </Card>
        </Fade>
      </Modal>
    </Page>
  );
}
