export const randomPasswordGenerator = (
  passwordLength= 12,
  useSymbols= true,
  useNumbers= true,
  useLowerCase= true,
  useUpperCase= true,
) => {
  let password = '';
  let charset = '';
  if (useSymbols) charset += '!@#$%^&*()';
  if (useNumbers) charset += '0123456789';
  if (useLowerCase) charset += 'abcdefghijklmnopqrstuvwxyz';
  if (useUpperCase) charset += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < passwordLength; i++) {
    password += charset.charAt(Math.floor(Math.random() * charset.length));
  }
  return password;
};
