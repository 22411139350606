import { useNavigate } from 'react-router-dom';

const Breadcrumbs = ({ separator = '›', navigates = [] }) => {
  const navigate = useNavigate();

  const handleClick = (path) => {
    navigate(path);
  };

  return (
    <div style={{ marginTop: 6, marginLeft: 10 }}>
      {navigates.map(({ path, name }, index) => (
        <>
          <span
            key={path}
            onClick={() => handleClick(path)}
            color={navigates.length === index + 1 ? 'text.primary' : 'inherit'}
            style={{ fontSize: 12, marginLeft: 18, cursor: navigates.length !== index + 1 ? 'pointer' : 'auto' }}
          >
            {name}
          </span>
          {navigates.length > index + 1 && (
            <span style={{ position: 'absolute', marginTop: 3, marginLeft: 5 }}>{separator}</span>
          )}
        </>
      ))}
    </div>
  );
};

export default Breadcrumbs;
