import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import { filter } from 'lodash';

import {
  Alert,
  Stack,
  Paper,
  Modal,
  Switch,
  Fade,
  CardHeader,
  TextField,
  Autocomplete,
  TableHead,
  IconButton,
  Button,
  Tooltip,
  Typography,
  Container,
  Grid,
  Checkbox,
  FormControlLabel,
  Card,
  CardActions,
  CircularProgress,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  TablePagination,
  styled,
} from '@mui/material';

import LinearProgress from '@mui/material/LinearProgress';
import { LoadingButton } from '@mui/lab';
import { ListHeads } from '../sections/@dashboard/user';
import Scrollbar from '../components/scrollbar';
import Page from '../components/Page';
import {
  fetchDocuwareVendors,
  fetchQuickBooksVendors,
  fetchQuickBooksGLAccounts,
  fetchVendorLines,
  fetchQuickBooksItems,
  saveVendorLineMaping,
  getBillConfigList,
  autoMatchVendors,
  createQuickbooksVendor,
} from '../services/api';
import API_URL from '../global';

let TABLE_HEAD = [
  { id: 'docuwareVendor', label: 'Docuware Vendor', alignRight: false },
  { id: 'action', label: '', alignRight: false },
  { id: 'qbVendor', label: 'QuickBooks Vendor', alignRight: false },
  { id: 'qbGl', label: 'QuickBooks GL', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const NewAutoComplete = styled(Autocomplete)(() => ({
  '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
    padding: '4px',
  },
  '& .css-12vka4q-MuiFormLabel-root-MuiInputLabel-root': {
    fontSize: 'small',
  },
}));

export default function VendorMaping() {
  useEffect(() => {
    const userId = localStorage.getItem('userId');
    const companyId = localStorage.getItem('userCompanyId');
    // TODO HERERERERERRE
    getBillConfigList(userId, companyId).then((res) => {
      if (res.data) {
        prepareConfigList(res.data);
      }
    });
    const configForVMapId = localStorage.getItem('configForVMapId');
    const configForVMapLabel = localStorage.getItem('configForVMapLabel');
    if (configForVMapId) {
      setConfig({ id: configForVMapId, label: configForVMapLabel });
      getVendorData(configForVMapId);
    }
  }, []);

  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isLineLoading, setIsLineLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState('');

  const [isShowAlert, setIsShowAlert] = useState(false);

  const [configList, setConfigList] = useState([]);
  const [config, setConfig] = useState(false);

  const [vendorMapingList, setVendorMapingList] = useState([]);
  const [vendorLineList, setVendorLineList] = useState([]);

  const [qbVendorLoading, setQbVendorLoading] = useState(false);
  const [vendorList, setVendorList] = useState([]);

  const [qbGLLoading, setQbGLLoading] = useState(false);
  const [qbGLList, setQbGLList] = useState([]);

  const [qbItemLoading, setQbItemLoading] = useState(false);
  const [qbItemList, setQbItemList] = useState([]);

  const [selectedDWVendor, setSelectedDWVendor] = useState(false);
  const [selectedQBVendor, setSelectedQBVendor] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(false);

  const [isLineConfigured, setIsLineConfigured] = useState(false);
  const [totalStoredVendor, setTotalStoredVendor] = useState(false);
  const [totalRemainingVendor, setTotalRemainingVendor] = useState(false);
  const [autoMatch, setAutoMatch] = useState(false);
  const [matchList, setMatchList] = useState({});
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [newUpdatedRows, setNewUpdatedRows] = useState([]);

  const handleOpen = (dwVendorName, qbVendorName, rowId) => {
    setSelectedDWVendor(dwVendorName);
    setSelectedQBVendor(qbVendorName);
    setSelectedRowId(rowId);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const filteredVendors = applySortFilter(vendorMapingList, getComparator(order, orderBy), '');
  const isNotFound = !filteredVendors.length;

  const prepareConfigList = (data) => {
    const configOptions = data
      ? data.map((item) => {
          const configItem = {};
          configItem.id = item.id;
          configItem.label = item.name;
          return configItem;
        })
      : [];
    setConfigList(configOptions);
  };

  const prepareItemList = (items) => {
    const itemOptions = items
      ? items.map((item) => {
          const itemVals = {};
          itemVals.id = item.id;
          itemVals.label = item.name;
          return itemVals;
        })
      : [];
    return itemOptions;
  };

  const prepareQBVendorList = (data) => {
    const vendorOptions = data
      ? data.map((item) => {
          const qbVendorItem = {};
          qbVendorItem.id = item.name;
          qbVendorItem.label = item.name;
          return qbVendorItem;
        })
      : [];
    setVendorList(vendorOptions);
  };

  const prepareGLList = (data) => {
    const glOptions = data
      ? data.map((item) => {
          const qbGLItem = {};
          qbGLItem.id = item.name;
          qbGLItem.label = item.name;
          return qbGLItem;
        })
      : [];
    setQbGLList(glOptions);
  };

  const prepareVendorMapingList = (vendorList) => {
    const vendorMapingList = vendorList
      ? vendorList.map((vendor, i) => {
          const vendorItem = {};
          vendorItem.isSaved = false;
          vendorItem.rowId = i + 1;
          vendorItem.docuwareVendor = vendor.vendor;
          vendorItem.documentIds = vendor.document_ids;
          vendorItem.qbVendor = false;
          vendorItem.qbGl = false;
          vendorItem.hasLines = false;
          return vendorItem;
        })
      : [];
    setVendorMapingList(vendorMapingList);
  };

  const prepareVendorLineList = (lineList) => {
    const vendorLineList = lineList
      ? lineList.map((vendor, i) => {
          const vendorLineItem = {};
          vendorLineItem.rowId = i + 1;
          vendorLineItem.isCategory = false;
          vendorLineItem.glCode = '';
          vendorLineItem.isItem = false;
          vendorLineItem.itemCode = '';
          vendorLineItem.lineName = vendor.line_name;
          return vendorLineItem;
        })
      : [];
    return vendorLineList;
  };

  const saveVendorMaping = async (data) => {
    const vendorData = {
      docuware_vendor_name: data.docuwareVendorName,
      qb_vendor_name: data.qbVendorName,
      gl_account: data.glAccount,
      docu_qb_config: data.configId,
      created_by: localStorage.getItem('userId'),
      updated_by: localStorage.getItem('userId'),
    };

    try {
      return await axios.post(`${API_URL}/docuware/api/create-vendor-maping/`, vendorData, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      });
    } catch (err) {
      return err;
    }
  };

  const handleCreateVendor = () => {
    setOpenModal(false);
    createQuickbooksVendor({ docu_qb_config_id: config.id, vendor_name: selectedVendor }).then((response) => {
      // TODO : Display Message here
      console.log(response);
    });
  };

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleLineSaveClick = () => {
    saveVendorLineMaping(config.id, selectedDWVendor, selectedQBVendor, vendorLineList).then((response) => {
      if (response.data && response.data.message === 'success') {
        handleClose();
        const updateLines = (lineList) =>
          lineList.map((row) => (row.rowId === selectedRowId ? { ...row, isSaved: true } : row));
        const updatedLines = updateLines(vendorMapingList);
        setVendorMapingList(updatedLines);
      } else {
        alert('Error while maping Line Items. Please try again later.');
        handleClose();
      }
    });
  };

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleCheckBoxChange = (event, rowId, field) => {
    const isChecked = event.target.checked;
    if (field === 'item') {
      const updateLineList = (lineList) =>
        lineList.map((row) => (row.rowId === rowId ? { ...row, isItem: isChecked, isCategory: !isChecked } : row));
      const updatedLineList = updateLineList(vendorLineList);
      setVendorLineList(updatedLineList);
    } else if (field === 'category') {
      const updateLineList = (lineList) =>
        lineList.map((row) => (row.rowId === rowId ? { ...row, isCategory: isChecked, isItem: !isChecked } : row));
      const updatedLineList = updateLineList(vendorLineList);
      setVendorLineList(updatedLineList);
    }
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - vendorMapingList.length) : 0;

  const fetchRowValue = (rowId, updatedList) => {
    const foundRow = updatedList.find((row) => row.rowId === rowId);
    return foundRow || false;
  };

  const saveVendorIfRowLoaded = (row, config, rows) => {
    if (row.qbVendor && row.qbGl) {
      const vendorData = {
        docuwareVendorName: row.docuwareVendor,
        qbVendorName: row.qbVendor.id || row.qbVendor,
        glAccount: row.qbGl.id || row.qbGl,
        configId: config.id,
      };
      saveVendorMaping(vendorData).then((response) => {
        if (response.status === 200) {
          const updatedRows = (rows) =>
            rows.map((rowItem) => (rowItem.rowId === row.rowId ? { ...rowItem, isSaved: true } : rowItem));
          const updatedList = updatedRows(rows);
          setVendorMapingList(updatedList);
        }
        return true;
      });
    }
    return false;
  };
  const switchLabel = { inputProps: { 'aria-label': 'Switch demo' } };

  const handleLineFlagChange = (rowId) => {
    const updatedRows = (itemList) =>
      itemList.map((row) => (row.rowId === rowId ? { ...row, hasLines: !row.hasLines } : row));
    const updatedVendorMapingList = updatedRows(vendorMapingList);
    setVendorMapingList(updatedVendorMapingList);
  };

  const handleLineCellValueChange = (_event, newValue, rowId, field) => {
    if (field === 'item') {
      const updatedRows = (itemList) =>
        itemList.map((row) => (row.rowId === rowId ? { ...row, itemCode: newValue.id, categoryCode: null } : row));
      const updatedItemList = updatedRows(vendorLineList);
      setVendorLineList(updatedItemList);
    } else if (field === 'category') {
      const updatedRows = (itemList) =>
        itemList.map((row) => (row.rowId === rowId ? { ...row, categoryCode: newValue.id, itemCode: null } : row));
      const updatedItemList = updatedRows(vendorLineList);
      setVendorLineList(updatedItemList);
    }
  };

  const getVendorData = (configId) => {
    setQbVendorLoading(true);
    setIsTableLoading(true);
    fetchDocuwareVendors(configId).then((response) => {
      const hasLineFieldConfigured = response.data.has_line_field_configured;
      const resTotalStoredVendor = response.data.total_stored_vendor_mapings || null;
      const resTotalRemainingVendor = response.data.total_remaining_vendor || null;
      setTotalStoredVendor(resTotalStoredVendor);
      setTotalRemainingVendor(resTotalRemainingVendor);
      setIsLineConfigured(hasLineFieldConfigured);
      if (hasLineFieldConfigured) {
        TABLE_HEAD = [
          { id: 'docuwareVendor', label: 'Docuware Vendor', alignRight: false },
          { id: 'action', label: '', alignRight: false },
          { id: 'qbVendor', label: 'QuickBooks Vendor', alignRight: false },
          { id: 'qbGl', label: 'QuickBooks GL', alignRight: false },
          { id: 'hasLine', label: 'Has Lines ?', alignRight: true },
          { id: 'line', label: 'Lines', alignRight: true },
        ];
      } else {
        TABLE_HEAD = [
          { id: 'docuwareVendor', label: 'Docuware Vendor', alignRight: false },
          { id: 'action', label: '', alignRight: false },
          { id: 'qbVendor', label: 'QuickBooks Vendor', alignRight: false },
          { id: 'qbGl', label: 'QuickBooks GL', alignRight: false },
        ];
      }

      setIsTableLoading(false);
      // TODO : filter and remove for which Vendor Maping is already done.
      prepareVendorMapingList(response.data.vendor_list || []);
    });
    fetchQuickBooksVendors(configId).then((response) => {
      if (response.data && response.data.status_code && response.data.status_code === 401) {
        console.log('Token Expired');
        alert('QuickBooks Token Expired,  Please update Token.');
      }
      if (response.data.vendor_list) {
        prepareQBVendorList(response.data.vendor_list);
        setQbVendorLoading(false);
      } else {
        console.log('Could not fetch Vendor From QuickBooks');
      }
      setQbVendorLoading(false);
    });
    fetchQuickBooksGLAccounts(configId).then((response) => {
      if (response.data && response.data.status_code && response.data.status_code === 401) {
        console.log('Token Expired');
      }
      if (response.data.gl_list) {
        prepareGLList(response.data.gl_list);
        setQbGLLoading(false);
      } else {
        console.log('Could not fetch Vendor From QuickBooks');
      }
    });
    setQbGLLoading(false);
  };

  const handleGLCellValueChange = (_event, fieldName, newValue, rowId) => {
    if (fieldName === 'qbVendor') {
      const updatedRows = (vendorMapingList) =>
        vendorMapingList.map((row) => (row.rowId === rowId ? { ...row, qbVendor: newValue } : row));
      const updatedList = updatedRows(vendorMapingList);
      setVendorMapingList(updatedList);
      const rowData = fetchRowValue(rowId, updatedList);
      const isSaved = saveVendorIfRowLoaded(rowData, config, updatedList);
      const data = vendorMapingList.find((t) => t.rowId === rowId);
      setNewUpdatedRows([...newUpdatedRows, { ...data, qbVendor: newValue }]);
    } else if (fieldName === 'qbGL') {
      const updatedRows = (vendorMapingList) =>
        vendorMapingList.map((row) => (row.rowId === rowId ? { ...row, qbGl: newValue } : row));
      const updatedList = updatedRows(vendorMapingList);
      setVendorMapingList(updatedList);
      const rowData = fetchRowValue(rowId, updatedList);
      const isSaved = saveVendorIfRowLoaded(rowData, config, updatedList);
      const data = vendorMapingList.find((t) => t.rowId === rowId);
      setNewUpdatedRows([...newUpdatedRows, { ...data, qbGl: newValue }]);
    }
  };

  const handleClickIcon = (value) => {
    setSelectedVendor(value);
    setOpenModal(true);
  };

  const handleAutoMatch = () => {
    setAutoMatch(true);
    const dwVendorListForMatch = extractDocuwareVendors(vendorMapingList);
    const qbVendorListForMatch = extractQBVendors(vendorList);
    setOpenInfoModal(true);
    autoMatchVendors({ dw_vendor_list: dwVendorListForMatch, qb_vendor_list: qbVendorListForMatch }).then(
      (response) => {
        if (Object.keys(response.data.matched_list).length) {
          // const data = { ADT: 'ADT', 'Advantage Financial Services': 'AFS', 'Analytix Business Solutions, LLC': 'ABS' };
          const data = response.data.matched_list;
          setMatchList(data);
          let updatingData = [];
          const newData = Object.keys(data).map((i) => {
            const d = vendorMapingList.find((t) => t.docuwareVendor === i);
            const updatedRows = (vendorMapingList) =>
              vendorMapingList.map((row) => (row.docuwareVendor === i ? { ...row, qbVendor: data[i] } : row));
            const updatedList = updatedRows(updatingData.length ? updatingData : vendorMapingList);
            updatingData = updatedList;
            const foundRow = updatedList.find((row) => row.docuwareVendor === i) || false;
            const isSaved = saveVendorIfRowLoaded(foundRow, config, updatedList);
            return { ...d, qbVendor: data[i] };
          });
          setNewUpdatedRows(newData);
          setVendorMapingList(updatingData);
        }
      }
    );
  };

  const extractDocuwareVendors = (data) => data.map((item) => item.docuwareVendor);

  const extractQBVendors = (data) => data.map((item) => item.label);

  const getMatchVendorValue = (value) => {
    const vendor = matchList?.[Object.keys(matchList).find((i) => i === value)];
    return vendorList.find((i) => i.label === vendor)?.label || null;
  };

  const handleSave = () => {};

  return (
    <Page title="DocuSync To QB">
      <Card variant="outlined" sx={{ mt: 0 }} style={{ overflow: 'visible', borderRadius: 5 }}>
        <Typography variant="h5" style={{ marginLeft: 20, marginTop: 10 }}>
          Vendor Mapping
        </Typography>
        {isShowAlert && (
          <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert
              severity="error"
              onClose={() => {
                setIsShowAlert(false);
              }}
            >
              Please Select <strong>Configuration</strong> First!
            </Alert>
          </Stack>
        )}
        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" sx={{ mb: 1, mt: 1, ml: -3, mr: -3 }}>
          <Container style={{ maxWidth: '100%' }}>
            <Scrollbar>
              <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" sx={{ mb: 3, mt: 3, ml: 5 }}>
                <Grid container spacing={3}>
                  <Grid item sx={5} md={5}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={configList || null}
                      disabled={false}
                      size={'small'}
                      value={config || null}
                      defaultValue={null}
                      onChange={(_event, value) => {
                        setConfig(value);
                        setIsShowAlert(false);
                        localStorage.setItem('configForVMapId', value.id);
                        localStorage.setItem('configId', value.id);
                        localStorage.setItem('configForVMapLabel', value.label);
                      }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => <TextField {...params} label={'Select Configuration'} />}
                    />
                  </Grid>
                  <Grid item sx={1} md={1} style={{ marginRight: 10 }}>
                    <LoadingButton
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      style={{ maxWidth: '100px', minWidth: '100px', maxHeight: '40px', minHeight: '40px' }}
                      onClick={() => {
                        getVendorData(config.id);
                      }}
                    >
                      Search
                    </LoadingButton>
                  </Grid>
                  <Grid item xs={false} md={4.8} />
                  <Grid item sx={1} md={1} style={{ marginTop: 8 }}>
                    <IconButton
                      onClick={() => {
                        if (config !== undefined && config !== null && config !== false) {
                          localStorage.setItem('configId', config.id);
                          navigate('/app/save-vendor-maping', { replace: false });
                        } else {
                          setIsShowAlert(true);
                        }
                      }}
                    >
                      <Tooltip title="View Saved Maping" arrow placement="right-end">
                        <Icon
                          style={{ color: 'grey', height: '25px', width: '25px' }}
                          icon={'mingcute:user-setting-line'}
                        />
                      </Tooltip>
                    </IconButton>
                  </Grid>
                </Grid>
              </Stack>
              <Stack>
                <Grid container spacing={2} style={{ width: '106%' }}>
                  <Grid item sx={4} md={4} style={{ marginLeft: 15, marginBottom: 10 }}>
                    <span>
                      {totalStoredVendor && totalStoredVendor > 0 && <span>Stored : {totalStoredVendor}</span>}
                    </span>
                    <span style={{ marginLeft: 10 }}>
                      {totalRemainingVendor && totalRemainingVendor > 0 && (
                        <span>Remaining : {totalRemainingVendor}</span>
                      )}
                    </span>
                  </Grid>
                  <Grid
                    item
                    sx={7}
                    md={7}
                    style={{ marginLeft: 15, marginBottom: 10, display: 'flex', justifyContent: 'end' }}
                  >
                    <Button
                      style={{ marginBottom: 15, marginRight: 10 }}
                      size="small"
                      color="inherit"
                      variant="outlined"
                      sx={{ borderColor: 'grey' }}
                      onClick={handleAutoMatch}
                      disabled={isTableLoading}
                    >
                      <Icon
                        style={{ color: 'green', height: '25px', width: '25px' }}
                        icon="material-symbols:all-match-sharp"
                      />
                      <span style={{ fontSize: 15, marginLeft: 5 }}>Auto Match</span>
                    </Button>
                    {!openInfoModal && autoMatch && (
                      <Button
                        style={{ marginBottom: 15 }}
                        size="small"
                        color="inherit"
                        variant="outlined"
                        sx={{ borderColor: 'grey' }}
                        onClick={handleSave}
                        disabled={isTableLoading}
                      >
                        <Icon style={{ color: '#2E3192', height: '20px', width: '20px' }} icon="ion:save-sharp" />
                        <span style={{ fontSize: 15, marginLeft: 5 }}>Save</span>
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Stack>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <ListHeads
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={vendorMapingList.length}
                    onRequestSort={handleRequestSort}
                  />
                  {isTableLoading ? (
                    <TableRow>
                      <TableCell align="center" colSpan={7}>
                        <LinearProgress />
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableBody>
                      {filteredVendors.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        const { rowId, isSaved, docuwareVendor, documentIds, qbVendor, qbGl, hasLines } = row;
                        const vendor = vendorList?.[Object.keys(vendorList).find((i) => i === docuwareVendor)];
                        const isVendorAlreadyPresent = Boolean(vendorList.find((i) => i.label === vendor)?.label || '');

                        return (
                          <TableRow style={{ height: 3 }} hover key={rowId} tabIndex={-1} role="checkbox">
                            <TableCell component="th" scope="row" style={{ paddingTop: 5, paddingBottom: 5 }}>
                              <Typography variant="subtitle2" noWrap>
                                <Grid container spacing={1}>
                                  <Grid item sx={6} md={6}>
                                    {docuwareVendor}
                                  </Grid>
                                </Grid>
                              </Typography>
                            </TableCell>
                            <TableCell component="th" scope="row" style={{ paddingTop: 5, paddingBottom: 5 }}>
                              {!isVendorAlreadyPresent && (
                                <Tooltip title={'Create Vendor'} placement="top" arrow>
                                  <IconButton
                                    size="large"
                                    color="inherit"
                                    style={{ paddingTop: 5 }}
                                    onClick={() => {
                                      handleClickIcon(docuwareVendor);
                                    }}
                                  >
                                    <Icon
                                      icon={'mingcute:user-add-2-line'}
                                      style={{ color: 'green', height: '20px', width: '20px' }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              )}
                              {isSaved === true && (
                                <Icon
                                  style={{
                                    color: 'green',
                                    height: '25px',
                                    width: '25px',
                                    marginBottom: -6,
                                    marginLeft: 5,
                                  }}
                                  icon={'iconamoon:cloud-yes-light'}
                                />
                              )}
                            </TableCell>
                            <TableCell align="left" style={{ paddingTop: 5, paddingBottom: 5 }}>
                              <NewAutoComplete
                                loading={qbVendorLoading}
                                id="combo-box-demo"
                                options={vendorList}
                                disabled={false}
                                size={'small'}
                                value={(autoMatch && getMatchVendorValue(docuwareVendor)) || qbVendor || null}
                                defaultValue={null}
                                onChange={(event, newValue) => {
                                  setAutoMatch(true);
                                  handleGLCellValueChange(event, 'qbVendor', newValue, rowId);
                                }}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderInput={(params) => <TextField {...params} placeholder={'Select Vendor'} />}
                              />
                            </TableCell>

                            <TableCell
                              align="left"
                              style={{ paddingTop: 5, paddingBottom: 5, paddingLeft: 0, paddingRight: 0 }}
                            >
                              <NewAutoComplete
                                loading={qbGLLoading}
                                id="combo-box-demo"
                                options={qbGLList}
                                disabled={false}
                                size={'small'}
                                value={qbGl || null}
                                defaultValue={null}
                                onChange={(event, newValue) => {
                                  setAutoMatch(true);
                                  handleGLCellValueChange(event, 'qbGL', newValue, rowId);
                                }}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderInput={(params) => <TextField {...params} placeholder={'Select GL Account'} />}
                              />
                            </TableCell>
                            {isLineConfigured && (
                              <>
                                <TableCell
                                  align="right"
                                  style={{ paddingTop: 5, paddingBottom: 5, paddingLeft: 0, paddingRight: 0 }}
                                >
                                  <Switch
                                    {...switchLabel}
                                    checked={hasLines}
                                    onChange={() => {
                                      handleLineFlagChange(rowId);
                                    }}
                                  />
                                </TableCell>
                                <TableCell align="center" style={{ paddingTop: 5, paddingBottom: 5 }}>
                                  {hasLines ? (
                                    <IconButton
                                      onClick={() => {
                                        if (
                                          docuwareVendor &&
                                          docuwareVendor !== undefined &&
                                          qbVendor &&
                                          qbVendor !== undefined
                                        ) {
                                          setIsLineLoading(true);
                                          setQbItemLoading(true);
                                          fetchQuickBooksItems(config.id).then((response) => {
                                            let itemList = [];
                                            if (response.data && response.data.item_list) {
                                              itemList = prepareItemList(response.data.item_list);
                                            }
                                            setQbItemList(itemList);
                                            setQbItemLoading(false);
                                          });
                                          fetchVendorLines(config.id, documentIds).then((res) => {
                                            const documentLines =
                                              (res.data &&
                                                res.data.document_lines[0] &&
                                                res.data.document_lines[0].vendor_lines) ||
                                              null;
                                            if (documentLines) {
                                              const vendorLineList = prepareVendorLineList(documentLines);
                                              setVendorLineList(vendorLineList);
                                            }
                                            setIsLineLoading(false);
                                          });
                                          handleOpen(docuwareVendor, qbVendor.id, rowId);
                                        } else {
                                          alert(
                                            'Please Map Docuware Vendor with QuickBooks Vendor In order to Map Line items.'
                                          );
                                        }
                                      }}
                                    >
                                      <Tooltip title="Line Item Maping" arrow placement="right-end">
                                        <Icon
                                          style={{ color: 'grey', height: '25px', width: '25px' }}
                                          icon={'ion:list-outline'}
                                        />
                                      </Tooltip>
                                    </IconButton>
                                  ) : (
                                    <>-</>
                                  )}
                                </TableCell>
                              </>
                            )}
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  )}

                  {isNotFound && !isTableLoading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              {!isTableLoading && (
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={vendorMapingList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </Scrollbar>
          </Container>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Fade in={open}>
              <Card variant="outlined" sx={{ mt: 0, width: '75%' }} style={{ borderRadius: 5 }}>
                <CardHeader title="Line Maping" />
                <TableContainer
                  component={Paper}
                  sx={{
                    marginTop: 5,
                    marginBottom: 23,
                    paddingLeft: 2,
                    paddingRight: 2,
                  }}
                >
                  {isLineLoading ? (
                    <CircularProgress style={{ marginLeft: 10 }} />
                  ) : (
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left" style={{ paddingTop: 5, paddingBottom: 5 }}>
                            Docuware Item Name
                          </TableCell>
                          <TableCell align="left" style={{ paddingTop: 5, paddingBottom: 5 }}>
                            Is It Item or Category ?
                          </TableCell>
                          <TableCell align="left" style={{ paddingTop: 5, paddingBottom: 5 }}>
                            Select for QuickBooks
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {vendorLineList.map((row) => (
                          <TableRow key={row.rowId}>
                            <TableCell align="left" style={{ paddingTop: 5, paddingBottom: 5 }}>
                              {row.lineName}
                            </TableCell>
                            <TableCell align="left" style={{ paddingTop: 5, paddingBottom: 5 }}>
                              {row.isItem}
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={row.isItem}
                                    onChange={(e) => {
                                      handleCheckBoxChange(e, row.rowId, 'item');
                                    }}
                                    name="jason"
                                  />
                                }
                                label="Item"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={row.isCategory}
                                    onChange={(e) => {
                                      handleCheckBoxChange(e, row.rowId, 'category');
                                    }}
                                    name="jason"
                                  />
                                }
                                label="GL Account"
                              />
                            </TableCell>
                            {row.isItem && (
                              <TableCell align="left" style={{ paddingTop: 5, paddingBottom: 5 }}>
                                <Autocomplete
                                  disablePortal
                                  loading={qbItemLoading}
                                  id="combo-box-demo"
                                  options={qbItemList}
                                  disabled={false}
                                  size={'small'}
                                  value={row.itemCode || null}
                                  defaultValue={null}
                                  style={{ padding: 4 }}
                                  onChange={(event, newValue) => {
                                    handleLineCellValueChange(event, newValue, row.rowId, 'item');
                                  }}
                                  isOptionEqualToValue={(option, value) => option.id === value.id}
                                  renderInput={(params) => <TextField {...params} label={'Select QB Item'} />}
                                />
                              </TableCell>
                            )}
                            {row.isCategory && (
                              <TableCell align="left" style={{ paddingTop: 5, paddingBottom: 5 }}>
                                <Autocomplete
                                  disablePortal
                                  loading={qbGLLoading}
                                  id="combo-box-demo"
                                  options={qbGLList}
                                  disabled={false}
                                  size={'small'}
                                  value={row.categoryCode || null}
                                  defaultValue={null}
                                  onChange={(event, newValue) => {
                                    handleLineCellValueChange(event, newValue, row.rowId, 'category');
                                  }}
                                  isOptionEqualToValue={(option, value) => option.id === value.id}
                                  renderInput={(params) => <TextField {...params} label={'Select QB GL'} />}
                                />
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </TableContainer>

                <div
                  style={{
                    marginLeft: 15,
                    marginRight: 10,
                    marginBottom: 5,
                  }}
                >
                  <CardActions>
                    <Button variant="contained" color="primary" onClick={handleLineSaveClick}>
                      Save
                    </Button>
                    <Button variant="contained" color="secondary" onClick={handleClose}>
                      Cancel
                    </Button>
                  </CardActions>
                </div>
              </Card>
            </Fade>
          </Modal>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openModal}
            onClose={() => setOpenModal(false)}
            closeAfterTransition
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Fade in={openModal}>
              <Card variant="outlined" sx={{ mt: 0, width: '40%' }} style={{ borderRadius: 5 }}>
                <Typography variant="h5" style={{ margin: 10 }}>
                  Create Vendor
                </Typography>
                <div style={{ display: 'flex', margin: 10, marginTop: 25, justifyContent: 'center' }}>
                  <TextField
                    label="Vendor Name"
                    required
                    fullWidth
                    size="small"
                    type="text"
                    value={selectedVendor}
                    onChange={(e) => {
                      setSelectedVendor(e.target.value);
                    }}
                  />
                </div>
                <div style={{ margin: 20, justifyContent: 'center' }}>
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    style={{
                      maxWidth: '100px',
                      minWidth: '100px',
                      maxHeight: '40px',
                      minHeight: '40px',
                      marginRight: 10,
                    }}
                    onClick={handleCreateVendor}
                    loading={false}
                    disabled={!selectedVendor}
                  >
                    Create
                  </LoadingButton>
                  <LoadingButton
                    onClick={() => setOpenModal(false)}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    style={{
                      backgroundColor: '#D43F3E',
                      maxWidth: '100px',
                      minWidth: '100px',
                      maxHeight: '40px',
                      minHeight: '40px',
                    }}
                    loading={false}
                  >
                    Cancel
                  </LoadingButton>
                </div>
              </Card>
            </Fade>
          </Modal>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openInfoModal}
            onClose={() => setOpenInfoModal(false)}
            closeAfterTransition
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Fade in={openInfoModal}>
              <Card variant="outlined" sx={{ mt: 0, width: '30%' }} style={{ borderRadius: 5 }}>
                <div style={{ margin: 20, fontSize: 18, alignItems: 'center' }}>
                  Auto Matched vendors with QB vendors, Select GL/Item to save mapping.
                </div>

                <LoadingButton
                  onClick={() => setOpenInfoModal(false)}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  style={{
                    maxWidth: '100px',
                    minWidth: '100px',
                    maxHeight: '40px',
                    minHeight: '40px',
                    marginLeft: '35%',
                    marginBottom: 20,
                    marginTop: 10,
                  }}
                  loading={false}
                >
                  OK
                </LoadingButton>
              </Card>
            </Fade>
          </Modal>
        </Stack>
      </Card>
    </Page>
  );
}
