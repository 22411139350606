import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';

import AccountPopover from './AccountPopover';

const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 50;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% )`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const StyledDiv = styled('div')(() => ({
  color: 'black', fontSize: 16, fontWeight: 600, display: 'flex',
  '&:hover': { color: 'grey' },
}));

export default function Header() {
  const companyName = localStorage.getItem('userCompanyName');
  const navigate = useNavigate();

  return (
    <StyledRoot
      style={{
        cursor: 'pointer',
        backgroundColor: 'white',
        boxShadow: '0px 2px 4px -1px rgba(145, 158, 171, 0.2)',
        borderRadius: 5,
      }}
    >
      <StyledToolbar>
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          <StyledDiv onClick={() => navigate('/select-company')} >
            <Icon style={{ color: 'grey', height: '25px', width: '25px', marginRight: 10 }} icon={'mdi:company'} />
            {companyName}
          </StyledDiv>
          <AccountPopover />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
