// material
import { useEffect, useState } from 'react';
// components
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { docuwareFieldList } from '../constant';

export default function FieldOptions(props) {
  const {
    options,
    disabled,
    size,
    error,
    value,
    hidden,
    defaultValue,
    onChange,
    helperText,
    required,
    placeHolderLabel,
  } = props;
  const [fieldList, setFieldList] = useState([]);

  const prepareFieldOptions = (values) => {
    const fieldSelection = values.map((fieldObject) => {
      const fieldItem = {};
      fieldItem.id = fieldObject.Id;
      fieldItem.label = fieldObject.Name;
      return fieldItem;
    });
    setFieldList(fieldSelection);
  };

  useEffect(() => {
    prepareFieldOptions(docuwareFieldList);
  }, []);

  return (
    <>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={options && options !== null ? options : fieldList}
        disabled={disabled || false}
        size={size || 'large'}
        style={{ border: error ? '1px solid #D43F3E' : '', borderRadius: 6 }}
        value={value || null}
        hidden={hidden}
        defaultValue={defaultValue || null}
        onChange={onChange}
        error={error || null}
        helperText={helperText || null}
        required={required || false}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => <TextField {...params} color="error" label={placeHolderLabel} />}
      />
      <span style={{ fontSize: 10, color: '#D43F3E' }}>{error}</span>
    </>
  );
}
