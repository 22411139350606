import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Modal } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { logout } from '../services/api';

// ----------------------------------------------------------------------

export default function Logout() {
  const navigate = useNavigate();

  const handleLogout = () => {
    logout().then(() => {
      localStorage.clear();
      navigate('/', { replace: true });
    });
  };

  const handleCancel = () => {
    navigate(-1);
  };

  function getModalStyle() {
    return {
      position: 'absolute',
      top: '40%',
      left: '40%',
      backgroundColor: 'white',
      width: 325,
    };
  }
  const [modalStyle] = useState(getModalStyle);

  const CustomModal = () => (
    <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open>
      <div style={modalStyle}>
        <div style={{ margin: 20, display: 'flex', justifyContent: 'center' }}>Are You Sure?</div>
        <div style={{ display: 'flex', margin: 20, justifyContent: 'center' }}>
          <LoadingButton
            onClick={handleLogout}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            style={{ maxWidth: '100px', minWidth: '100px', maxHeight: '40px', minHeight: '40px', marginRight: 10, backgroundColor: '#D43F3E' }}
            loading={false}
          >
            Logout
          </LoadingButton>
          <LoadingButton
            onClick={handleCancel}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            style={{ maxWidth: '100px', minWidth: '100px', maxHeight: '40px', minHeight: '40px' }}
            loading={false}
          >
            Cancel
          </LoadingButton>
        </div>
      </div>
    </Modal>
  );

  return (
    <div>
      <CustomModal />
    </div>
  );
}
