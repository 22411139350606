import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';

import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

import Page from '../components/Page';
import Breadcrumbs from '../components/Breadcrumbs';

export default function PurchaseOrderDetails() {
  const [isEdit, setIsEdit] = useState(false);
  const poDetailsData = JSON.parse(localStorage.getItem('poDetailsData'));
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalUnitPrice, setTotalUnitPrice] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const getTotalValue = () => {
    const data = poDetailsData.purchase_order_lines;
    let totalQuantity = 0;
    let totalUnitPrice = 0;
    let totalAmount = 0;
    data.forEach((i) => {
      totalQuantity += i.quantity;
      totalUnitPrice += i.unit_price;
      totalAmount += i.amount;
    });
    setTotalQuantity(totalQuantity);
    setTotalUnitPrice(totalUnitPrice);
    setTotalAmount(totalAmount);
  };

  useEffect(() => {
    if (poDetailsData?.purchase_order_lines) {
      getTotalValue();
    }
  }, []);

  const navigates = [
    { path: '/app/po-transactions', name: 'Purchase Order Transactions' },
    { path: '', name: 'Purchase Order Transaction Details' },
  ];

  return (
    <div>
      <Page title="PO Transaction Details">
        <Card variant="outlined" sx={{ mt: 0 }} style={{ overflow: 'visible', borderRadius: 5 }}>
          <Breadcrumbs separator=">" navigates={navigates} />
          <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" sx={{ mb: 10, mt: 3 }}>
            <Container style={{ maxWidth: '100%' }}>
              <Stack style={{ marginTop: 10, width: 650 }} spacing={2}>
                <Box sx={{ textAlign: 'left', display: 'flex' }}>
                  <Typography variant="h5" style={{ marginTop: 5 }}>
                    Purchase Order Transaction Details
                  </Typography>
                  {/* <Button
                    style={{ marginLeft: 15 }}
                    size="small"
                    color="inherit"
                    variant="outlined"
                    sx={{ borderColor: 'grey' }}
                    onClick={() => setIsEdit(true)}
                  >
                    <Icon style={{ color: '#2E3192', height: '20px', width: '20px' }} icon="carbon:edit" />
                    <span style={{ fontSize: 15, marginLeft: 5 }}>Edit</span>
                  </Button> */}
                  {isEdit && (
                    <Button
                      style={{ marginLeft: 15 }}
                      size="small"
                      color="inherit"
                      variant="outlined"
                      sx={{ borderColor: 'grey' }}
                      onClick={() => {}}
                    >
                      <Icon style={{ color: '#2E3192', height: '20px', width: '20px' }} icon="ion:save-sharp" />
                      <span style={{ fontSize: 15, marginLeft: 5 }}>Save</span>
                    </Button>
                  )}
                </Box>
                <Divider />
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    Purchase Order Id
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      fullWidth
                      autoComplete="purchaseOrderId"
                      size="small"
                      value={poDetailsData.purchase_order_id}
                      InputProps={{
                        readOnly: true,
                      }}
                      type="text"
                      label={!poDetailsData.purchase_order_id && 'Purchase Order Id'}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    PO Status
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      fullWidth
                      autoComplete="poStatus"
                      size="small"
                      value={poDetailsData.po_status}
                      InputProps={{
                        readOnly: true,
                      }}
                      type="text"
                      label={!poDetailsData.po_status && 'PO Status'}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    Due Date
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      fullWidth
                      autoComplete="dueDate"
                      size="small"
                      value={poDetailsData.due_date}
                      InputProps={{
                        readOnly: true,
                      }}
                      type="text"
                      label={!poDetailsData.due_date && 'Due Date'}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    Account Name
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      fullWidth
                      autoComplete="apAccountName"
                      size="small"
                      value={poDetailsData.ap_account_name}
                      InputProps={{
                        readOnly: true,
                      }}
                      type="text"
                      label={!poDetailsData.ap_account_name && 'Account Name'}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    Class Name
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      fullWidth
                      autoComplete="className"
                      size="small"
                      value={poDetailsData.class_name}
                      InputProps={{
                        readOnly: true,
                      }}
                      type="text"
                      label={!poDetailsData.class_name && 'Class Name'}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    Department
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      fullWidth
                      autoComplete="department"
                      size="small"
                      value={poDetailsData.department}
                      InputProps={{
                        readOnly: true,
                      }}
                      type="text"
                      label={!poDetailsData.department && 'Department'}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    Memo
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      fullWidth
                      autoComplete="memo"
                      size="small"
                      value={poDetailsData.memo}
                      InputProps={{
                        readOnly: true,
                      }}
                      type="text"
                      label={!poDetailsData.memo && 'Memo'}
                    />
                  </Grid>
                </Grid>
                {/* <Grid container spacing={2}>
                  <Grid item xs={5}>
                    Purchase Order Mapping
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      fullWidth
                      autoComplete="poMapping"
                      size="small"
                      value={poDetailsData.po_mapping}
                      InputProps={{
                        readOnly: true,
                      }}
                      type="text"
                      label={!poDetailsData.po_mapping && 'Purchase Order Mapping'}
                    />
                  </Grid>
                </Grid> */}
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    Private Note
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      fullWidth
                      autoComplete="privateNote"
                      size="small"
                      value={poDetailsData.private_note}
                      InputProps={{
                        readOnly: true,
                      }}
                      type="text"
                      label={!poDetailsData.private_note && 'Privete Note'}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    Total Amount
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      fullWidth
                      autoComplete="totalAmount"
                      size="small"
                      value={poDetailsData.total_amount}
                      InputProps={{
                        readOnly: true,
                      }}
                      type="text"
                      label={!poDetailsData.total_amount && 'Total Amount'}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    Transaction Date
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      fullWidth
                      autoComplete="transactionDate"
                      size="small"
                      value={poDetailsData.txn_date}
                      InputProps={{
                        readOnly: true,
                      }}
                      type="text"
                      label={!poDetailsData.txn_date && 'Transaction Date'}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    Vendor Address
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      fullWidth
                      autoComplete="venderAddress"
                      size="small"
                      value={poDetailsData.vendor_address}
                      InputProps={{
                        readOnly: true,
                      }}
                      type="text"
                      label={!poDetailsData.vendor_address && 'Vendor Address'}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    Vendor Name
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      fullWidth
                      autoComplete="vendorName"
                      size="small"
                      value={poDetailsData.vendor_name}
                      InputProps={{
                        readOnly: true,
                      }}
                      type="text"
                      label={!poDetailsData.vendor_name && 'Vendor Name'}
                    />
                  </Grid>
                </Grid>
              </Stack>
              {Boolean(poDetailsData.purchase_order_lines.length) && (
                <Stack style={{ marginTop: 10, width: '100%' }} spacing={2}>
                  <Divider />
                  <Typography variant="h5">Purchase Order Line Items</Typography>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Item Name</TableCell>
                          <TableCell align="left">Description</TableCell>
                          <TableCell align="right">Unit Price</TableCell>
                          <TableCell align="right">Quantity</TableCell>
                          <TableCell align="right">Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {poDetailsData.purchase_order_lines.map((item) => (
                          <TableRow key={item.qb_po_line_id}>
                            <TableCell align="left">{item.item_name}</TableCell>
                            <TableCell align="left">{item.description}</TableCell>
                            <TableCell align="right">
                              {item.unit_price
                                ? Number(item.unit_price).toLocaleString(undefined, { minimumFractionDigits: 2 })
                                : ''}
                            </TableCell>
                            <TableCell align="right">{item.quantity}</TableCell>
                            <TableCell align="right">
                              {item.amount
                                ? Number(item.amount).toLocaleString(undefined, { minimumFractionDigits: 2 })
                                : ''}
                            </TableCell>
                          </TableRow>
                        ))}

                        {/* Total Count Start */}
                        <TableRow>
                          <TableCell align="left"> </TableCell>
                          <TableCell align="right"> </TableCell>
                          <TableCell align="right">
                            {/* <b>
                              {totalUnitPrice
                                ? Number(totalUnitPrice).toLocaleString(undefined, { minimumFractionDigits: 2 })
                                : ''}
                            </b> */}
                          </TableCell>
                          <TableCell align="right">
                            {/* <b>{totalQuantity}</b> */}
                            <b>Total</b>
                          </TableCell>
                          <TableCell align="right">
                            <b>
                              {totalAmount
                                ? Number(totalAmount).toLocaleString(undefined, { minimumFractionDigits: 2 })
                                : ''}
                            </b>
                          </TableCell>
                        </TableRow>
                        {/* Total Count End */}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
              )}
            </Container>
          </Stack>
        </Card>
      </Page>
    </div>
  );
}
