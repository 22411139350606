/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Icon } from '@iconify/react';
import { alpha } from '@mui/material/styles';
import { Avatar, IconButton, Popover } from '@mui/material';
// mocks_
import { account, menuData } from '../../../_mock/account';
import { getCompanyFeatureFlag } from '../../../services/api';

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();
  const [poFeatureFlag, setPoFeatureFlag] = useState(false);

  useEffect(()=>{
    const companyId = localStorage.getItem('userCompanyId');

    getCompanyFeatureFlag({ companyId, featureName: 'Purchase Order' }).then((response) => {
      setPoFeatureFlag(response.data.is_enabled);
    });
  },[]);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = (path) => {
    setOpen(null);
    navigate(path);
  };

  const changeBackground = (e, type) => {
    e.target.style.textDecoration = type === 'enter' ? 'underline' : 'none';
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>
      <Popover
        id={'id'}
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div style={{ margin: '20px', width: 'auto' }}>
          <div style={{ display: 'flex' }}>
            {menuData.map((i) => (
              <div key={i.index} style={{ marginLeft: '20px', cursor: 'pointer', marginRight: '20px' }}>
                <div style={{ fontWeight: '600', marginBottom: '10px', marginLeft: '-14px' }}>{i.title}</div>
                {i.menu.map((m) => {
                  if (m.name === 'Purchase Order Mapping' && !poFeatureFlag) {
                    return <></>;
                  }
                  return (
                    <div
                      key={m.index}
                      onClick={() => handleClose(m.path)}
                      onMouseOver={(e) => changeBackground(e, 'enter')}
                      onMouseOut={(e) => changeBackground(e, 'leave')}
                      style={{ display: 'flex', alignItems: 'center', fontSize: '0.875rem', marginBottom: '5px' }}
                    >
                      <Icon
                        style={{ color: 'grey', height: '18px', width: '18px', marginLeft: -15, marginRight: 15 }}
                        icon={m.icon}
                      />
                      <div>{m.name}</div>
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
          <hr />
          <div
            onClick={() => handleClose('/app/logout')}
            onMouseOver={(e) => changeBackground(e, 'enter')}
            onMouseOut={(e) => changeBackground(e, 'leave')}
            style={{ marginLeft: '20px', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          >
            <Icon
              style={{ color: 'grey', height: '18px', width: '18px', marginLeft: -15, marginRight: 15 }}
              icon={'ri:logout-circle-line'}
            />
            Logout
          </div>
        </div>
      </Popover>
    </>
  );
}
