import { Link as RouterLink } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { filter } from 'lodash';
// material
import {
  Stack,
  Paper,
  Typography,
  Container,
  Grid,
  Card,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  TablePagination,
} from '@mui/material';

import LinearProgress from '@mui/material/LinearProgress';
import { ListHeads } from '../sections/@dashboard/user';
import Scrollbar from '../components/scrollbar';
import Page from '../components/Page';
import { getBillConfigList } from '../services/api';
// ----------------------------------------------------------------------

const TABLE_HEAD = [{ id: 'billMappingName', label: 'Bill Mappings ( Config. )', alignRight: false }];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function BillConfigList() {
  useEffect(() => {
    setIsTableLoading(true);
    const userId = localStorage.getItem('userId');
    const companyId = localStorage.getItem('userCompanyId');
    const qbCompanyId =
      JSON.parse(localStorage.getItem('qbCompany')) !== undefined
        ? JSON.parse(localStorage.getItem('qbCompany')).id
        : null;

    getBillConfigList(userId, companyId).then((res) => {
      if (res.data && res.data !== undefined) {
        const confList = prepareConfigList(res.data);
        setConfigList(confList);
        setIsTableLoading(false);
      }
    });
  }, []);

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [configList, setConfigList] = useState([]);

  const filteredConfigList = applySortFilter(configList, getComparator(order, orderBy), filterName);
  const isNotFound = !filteredConfigList.length && !!filterName;

  const prepareConfigList = (data) => {
    const configOptions = data
      ? data.map((item) => {
          const configItem = {};
          configItem.id = item.id;
          configItem.name = item.name;
          configItem.fileCabinet = item.file_cabinet;
          return configItem;
        })
      : [];
    return configOptions;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - configList.length) : 0;

  return (
    <Page title="DocuSync To QB">
      <Card variant="outlined" sx={{ mt: 0 }} style={{ borderRadius: 5 }}>
        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" sx={{ mb: 1, mt: 1, ml: -3, mr: -3 }}>
          <Container style={{ maxWidth: '100%' }}>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <ListHeads
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={configList.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                  />
                  {isTableLoading ? (
                    <TableRow>
                      <TableCell align="center" colSpan={7}>
                        <LinearProgress />
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableBody>
                      {filteredConfigList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                        const { id, name, fileCabinet } = row;
                        const selectedUser = selected.indexOf(name) !== -1;

                        return (
                          <TableRow
                            style={{ height: 3, overflow: 'visible' }}
                            hover
                            key={name}
                            tabIndex={-1}
                            role="checkbox"
                            selected={selectedUser}
                          >
                            <TableCell component="th" scope="row">
                              <Typography variant="subtitle2" noWrap>
                                <Grid item sx={6} md={6}>
                                  <RouterLink to={`/app/bill-maping/${id}/${fileCabinet}`}>{name}</RouterLink>
                                </Grid>
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  )}

                  {isNotFound && !isTableLoading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Try checking for typos or using complete words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              {!isTableLoading && (
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={configList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </Scrollbar>
          </Container>
        </Stack>
      </Card>
    </Page>
  );
}
