import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { styled, alpha } from '@mui/material/styles';
import {
  Box,
  Link,
  Drawer as MuiDrawer,
  IconButton,
  AppBar as MuiAppBar,
  List,
  ListItem as MuiListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Icon } from '@iconify/react';

import useResponsive from '../../../hooks/useResponsive';
import logo from '../../../images/docusync_logo-straight.png';
import profileLogo from "../../../images/docusync_logo-profile_icon.png";
import navConfig from './config';
import { getCompanyFeatureFlag } from '../../../services/api';

const OPEN_NAV_WIDTH = 280;
const CLOSE_NAV_WIDTH = 80;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ onClose }) {
  const { pathname } = useLocation();
  const isDesktop = useResponsive('up', 'lg');
  const [open, setOpen] = useState(isDesktop);
  const [menuOptions, setMenuOptions] = useState(navConfig);
  const [poFeatureFlag, setPoFeatureFlag] = useState(false);

  const navigate = useNavigate();

  useEffect(()=>{
    const companyId = localStorage.getItem('userCompanyId');
    getCompanyFeatureFlag({ companyId, featureName: 'Purchase Order' }).then((response) => {
      setPoFeatureFlag(response.data.is_enabled);
    });
  },[]);

  useEffect(() => {
    const isAdminUser = localStorage.getItem('isAdmin', false);
    if (isAdminUser === 'false') {
      const filteredNavConfig = navConfig.filter(({ title }) => title !== 'Manage Company');
      setMenuOptions(filteredNavConfig);
    }
  }, [pathname]);

  const handleDrawer = () => {
    setOpen(!open);
    localStorage.setItem('isSidebarOpen', !open);
    onClose(!open);
  };

  const openedMixin = (theme) => ({
    width: OPEN_NAV_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    [theme.breakpoints.up('sm')]: {
      minHeight: 40,
    },
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: OPEN_NAV_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }));

  const ListItem = styled(MuiListItem)(({ theme, path }) => ({
    backgroundColor: path === pathname ? alpha(theme.palette.grey[800], 0.12) : '',
  }));

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: OPEN_NAV_WIDTH,
      width: `calc(100% - ${OPEN_NAV_WIDTH}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const renderContent = (
    <List style={{ paddingTop: open ? 0 : 15 }}>
      {menuOptions.map(({ title, path, icon }) => {
        const companyName = localStorage.getItem('userCompanyName');
        return ( title === 'PO Transactions' &&  !poFeatureFlag ? null :
          <ListItem path={path} key={title} disablePadding sx={{ display: 'block' }} onClick={() => navigate(path)}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              {title === '' && open ? (
                <Link underline="none" style={{ width: '100%', paddingTop: 0 }}>
                  <StyledAccount>
                    <img src={logo} alt='docuSync_logo'/>
                  </StyledAccount>
                </Link>
              ) : (
                <>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    {title === '' ? (
                      <img style={{ width: 35, height: 35 }} src={profileLogo} alt='docuSync_Logo_profile'/>
                    ) : (
                      <Icon style={{ color: 'grey', height: '25px', width: '25px' }} icon={icon} />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={title === 'Change Company' ? companyName : title}
                    sx={{ opacity: open ? 1 : 0 }}
                    primaryTypographyProps={{ fontSize: 14, color: title === 'Change Company' && '#2E3192' }}
                  />
                </>
              )}
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );

  return (
    <Box
      component="nav"
      sx={{
        width: { lg: open ? OPEN_NAV_WIDTH : CLOSE_NAV_WIDTH },
      }}
    >
      <AppBar position="fixed" open={open}>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawer}>
              <Icon
                style={{ color: '#2E3192', height: '25px', width: '25px' }}
                icon={open ? 'fe:arrow-left' : 'ion:menu'}
              />
            </IconButton>
          </DrawerHeader>
          {renderContent}
        </Drawer>
      </AppBar>
    </Box>
  );
}
