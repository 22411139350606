import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';

import {
  Stack,
  Alert,
  TextField,
  IconButton,
  InputAdornment,
  Typography,
  Container,
  Grid,
  Card,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';

import { LoadingButton } from '@mui/lab';
import Page from '../components/Page';
import {
  connectDocuwareClient,
  getQuickBooksConnectUrl,
  checkIfDWClientExists,
  checkIfQBClientExists,
} from '../services/api';

export default function DocuwareConnect() {
  const navigate = useNavigate();

  const [qbConnectUrl, setQbConnectUrl] = useState('');
  const [isDocuwareExists, setIsDocuwareExists] = useState(false);
  const [docuwareClientName, setDocuwareClientName] = useState('');
  const [docuwareUserName, setDocuwareUserName] = useState('');
  const [isQBExists, setIsQBExists] = useState(false);
  const [editQuickBookConnection, setEditQuickBookConnection] = useState(false);
  const [QBCompany, setQBCompany] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [displayMessage, setDisplayMessage] = useState(false);
  const [isShowError, setIsShowError] = useState(false);
  const [isShowSuccess, setIsShowSuccess] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [editConnection, setEditConnection] = useState(false);
  const [prevProps, setPrevProps] = useState({ url: '', name: '' });
  const [isNextClicked, setIsNextClicked] = useState(false);
  const [showConnectionError, setShowConnectionError] = useState(false);

  const LoginSchema = Yup.object().shape({
    serverUrl: Yup.string().required('Server URL is required'),
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
  });
  const checkDwClient = () => {
    const userId = localStorage.getItem('userId') || null;
    const userCompanyId = localStorage.getItem('userCompanyId') || null;
    checkIfDWClientExists({ userId, companyId: userCompanyId }).then((res) => {
    const docuwareClient = res.data.docuware_client || null;
    if (docuwareClient) {
      setIsDocuwareExists(true);
      setDocuwareClientName(docuwareClient.server_url);
      setDocuwareUserName(docuwareClient.username);
    } else {
      setIsDocuwareExists(false);
    }
  });
}
  useEffect(() => {
    const userId = localStorage.getItem('userId') || null;
    const userCompanyId = localStorage.getItem('userCompanyId') || null;
    if (userId && userCompanyId) {
      checkDwClient();
      checkIfQBClientExists({ userId, companyId: userCompanyId }).then((res) => {
        const companyName = (res.data && res.data.company_name) || null;
        const qbCompanyId = (res.data && res.data.id) || null;
        localStorage.setItem('qbCompany', JSON.stringify({ id: qbCompanyId, name: companyName }));
        if (companyName) {
          setIsQBExists(true);
          setQBCompany(companyName);
        } else {
          setIsQBExists(false);
        }
      });
      getQuickBooksConnectUrl({userCompanyId}).then((res) => {
        const { url } = res.data;
        setQbConnectUrl(url);
      });
    } else {
      alert('User and Company is not Set, Please Contact Support.');
    }
  }, []);

  useEffect(() => {
    if (editConnection) {
      setPrevProps({ url: docuwareClientName, name: docuwareUserName });
    }
  }, [editConnection]);

  const formik = useFormik({
    initialValues: {
      serverUrl: '',
      username: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      setIsBtnLoading(true);
      const userCompanyId = localStorage.getItem('userCompanyId') || null;
      connectDocuwareClient({...formik.values,userCompanyId})
        .then((res) => {
          const status = res.data && res.data.status;
          if (status && status === 'success') {
            setDisplayMessage('Docuware Connected');
            setIsShowSuccess(true);
            setIsBtnLoading(false);
            setEditConnection(false);
            checkDwClient();
          } else if (status && status !== 'success') {
            setDisplayMessage(status);
            setIsShowError(true);
            setIsBtnLoading(false);
          }
        })
        .catch(() => {
          setDisplayMessage('Issue while Connecting to Docuware');
          setIsShowError(true);
          setIsBtnLoading(false);
        });
    },
  });

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleClick = (index) => {
    if (index === 1 && !isDocuwareExists) {
      setShowConnectionError(true);
      return;
    }
    if (index === 'next') {
      if (!isQBExists) {
        setIsNextClicked(true);
        setTimeout(() => {
          setIsNextClicked(false);
        }, 2000);
        return;
      }
      navigate('/app/config');
      return;
    }
    setActiveStep(index);
  };

  const handleEdit = () => {
    setEditConnection(!editConnection);
    setFieldValue('serverUrl', docuwareClientName);
    setFieldValue('username', docuwareUserName);
    setFieldValue('password', "");
    if (editConnection) {
      setDocuwareClientName(prevProps.url);
      setDocuwareUserName(prevProps.name);
    }
  };

  const handleUrlInput = (value) => {
    formik.values.serverUrl = value;
    setDocuwareClientName(value);
  };

  const handleUserNameInput = (value) => {
    formik.values.username = value;
    setDocuwareUserName(value);
  };

  const handleNext = () => {
    if (isDocuwareExists) {
      handleClick(1);
    } else {
      setShowConnectionError(true);
    }
  };

  return (
    <Page title="DocuSync: Connect">
      <Card variant="outlined" style={{ borderRadius: 5 }}>
        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" sx={{ mb: 0, mt: 5 }}>
          <Container style={{ maxWidth: '100%' }}>
            <Grid container spacing={2}>
              {isShowError && (
                <Stack sx={{ width: '100%' }} spacing={2}>
                  <Alert
                    severity="error"
                    onClose={() => {
                      setIsShowError(false);
                    }}
                  >
                    {displayMessage}
                  </Alert>
                </Stack>
              )}
              {isShowSuccess && (
                <Stack sx={{ width: '100%' }} spacing={2}>
                  <Alert
                    severity="success"
                    onClose={() => {
                      setIsShowSuccess(false);
                    }}
                  >
                    {displayMessage}
                  </Alert>
                </Stack>
              )}
              {showConnectionError && (
                <Stack sx={{ width: '100%' }} spacing={2}>
                  <Alert
                    severity="error"
                    onClose={() => {
                      setShowConnectionError(false);
                    }}
                  >
                    <b>Docuware</b> Is Not Connected!
                  </Alert>
                </Stack>
              )}
              {isNextClicked && !isQBExists && (
                <Stack sx={{ width: '100%' }} spacing={2}>
                  <Alert
                    severity="error"
                    onClose={() => {
                      setIsNextClicked(false);
                    }}
                  >
                    <b>QuickBooks</b> Is Not Connected!
                  </Alert>
                </Stack>
              )}
              <Grid item sx={12} md={12}>
                <Stepper nonLinear activeStep={activeStep}>
                  {['Connect with Docuware', 'Connect with QuickBooks'].map((label, index) => (
                    <Step key={label} completed={false}>
                      <div onClick={() => handleClick(index)} style={{ cursor: 'pointer' }}>
                        <StepLabel>{label}</StepLabel>
                      </div>
                    </Step>
                  ))}
                </Stepper>
                <Grid item sx={12} md={12} style={{ display: 'flex' }}>
                  <div style={{ margin: 35, width: '100%' }}>
                    {activeStep === 0 && (
                      <>
                        <Typography variant="h5" sx={{ mb: 4 }}>
                          Connect with Docuware
                        </Typography>
                        {isDocuwareExists && !editConnection && (
                          <Alert severity="success">
                            <b>{docuwareClientName}</b> Is Connected!
                          </Alert>
                        )}
                        <br />
                        <FormikProvider value={formik}>
                          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                            <Stack spacing={2}>
                              {(!isDocuwareExists || editConnection) && (
                                <>
                                  <TextField
                                    fullWidth
                                    autoComplete="serverUrl"
                                    size="small"
                                    type="text"
                                    label="Server Url"
                                    disabled={isDocuwareExists && !editConnection}
                                    style={{ maxWidth: '400px', minWidth: '400px' }}
                                    value={docuwareClientName}
                                    onChange={(e) => handleUrlInput(e.target.value)}
                                    error={Boolean(touched.serverUrl && errors.serverUrl)}
                                    helperText={touched.serverUrl && errors.serverUrl}
                                  />

                                  <TextField
                                    fullWidth
                                    autoComplete="username"
                                    size="small"
                                    style={{ maxWidth: '400px', minWidth: '400px' }}
                                    type="text"
                                    label="Username"
                                    disabled={isDocuwareExists && !editConnection}
                                    value={docuwareUserName}
                                    onChange={(e) => handleUserNameInput(e.target.value)}
                                    error={Boolean(touched.username && errors.username)}
                                    helperText={touched.username && errors.username}
                                  />

                                  <TextField
                                    fullWidth
                                    autoComplete="current-password"
                                    size="small"
                                    style={{ maxWidth: '400px', minWidth: '400px' }}
                                    type={showPassword ? 'text' : 'password'}
                                    disabled={isDocuwareExists && !editConnection}
                                    label="Password"
                                    {...getFieldProps('password')}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton onClick={handleShowPassword} edge="end">
                                            <Icon icon={showPassword ? 'mingcute:eye-2-fill' : 'eva:eye-off-fill'} />
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                    error={Boolean(touched.password && errors.password)}
                                    helperText={touched.password && errors.password}
                                  />
                                </>
                              )}
                              <Grid container spacing={0}>
                                <Grid item sx={3} md={3} style={{ display: 'flex' }}>
                                  {(!isDocuwareExists || editConnection) && (
                                    <LoadingButton
                                      fullWidth
                                      size="large"
                                      type="submit"
                                      disabled={isDocuwareExists && !editConnection}
                                      variant="contained"
                                      style={{
                                        maxWidth: '100px',
                                        minWidth: '100px',
                                        maxHeight: '40px',
                                        minHeight: '40px',
                                      }}
                                      loading={isBtnLoading}
                                    >
                                      Connect
                                    </LoadingButton>
                                  )}
                                  {isDocuwareExists && (
                                    <LoadingButton
                                      fullWidth
                                      size="large"
                                      type="submit"
                                      onClick={handleEdit}
                                      variant="contained"
                                      style={{
                                        maxWidth: '80px',
                                        minWidth: '80px',
                                        maxHeight: '40px',
                                        minHeight: '40px',
                                        marginLeft: 10,
                                        backgroundColor: editConnection && '#D43F3E',
                                      }}
                                    >
                                      {editConnection ? 'Cancel' : 'Edit'}
                                    </LoadingButton>
                                  )}
                                  <LoadingButton
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    disabled={(!isDocuwareExists || editConnection)}
                                    onClick={handleNext}
                                    variant="contained"
                                    style={{
                                      maxWidth: '80px',
                                      minWidth: '80px',
                                      maxHeight: '40px',
                                      minHeight: '40px',
                                      marginLeft: 10,
                                    }}
                                  >
                                    Next
                                  </LoadingButton>
                                </Grid>
                              </Grid>
                            </Stack>
                          </Form>
                        </FormikProvider>
                      </>
                    )}
                    {/* ---------------------------- */}
                    {activeStep === 1 && (
                      <>
                        <Typography variant="h5" sx={{ mb: 2 }}>
                          Connect with QuickBooks
                        </Typography>
                        {isQBExists && (
                          <Alert severity="success" style={{ width: '100%' }}>
                            <b>{QBCompany}</b> Is Connected!
                          </Alert>
                        )}
                        <br />
                        {(!isQBExists || editQuickBookConnection) && (
                          <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            disabled={isQBExists && !editQuickBookConnection}
                            onClick={(e) => {
                              if (qbConnectUrl) {
                                window.location.href = qbConnectUrl;
                              } else {
                                alert('Missing QuickBooks Company Configuration.');
                              }
                            }}
                            style={{
                              maxWidth: '260px',
                              minWidth: '260px',
                              maxHeight: '40px',
                              minHeight: '40px',
                              backgroundColor: '#329b24',
                            }}
                            loading={isBtnLoading}
                          >
                            Connect with QuickBooks
                          </LoadingButton>
                        )}
                        <LoadingButton
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          style={{
                            maxWidth: '80px',
                            minWidth: '80px',
                            maxHeight: '40px',
                            minHeight: '40px',
                            marginLeft: 10,
                          }}
                          loading={isBtnLoading}
                          onClick={() => handleClick(0)}
                        >
                          Back
                        </LoadingButton>
                        {isQBExists && !editQuickBookConnection && (
                          <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            style={{
                              maxWidth: '80px',
                              minWidth: '80px',
                              maxHeight: '40px',
                              minHeight: '40px',
                              marginLeft: 10,
                            }}
                            loading={isBtnLoading}
                            onClick={() => setEditQuickBookConnection(true)}
                          >
                            Edit
                          </LoadingButton>
                        )}
                        {editQuickBookConnection && (
                          <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            style={{
                              maxWidth: '80px',
                              minWidth: '80px',
                              maxHeight: '40px',
                              minHeight: '40px',
                              marginLeft: 10,
                              backgroundColor: '#D43F3E',
                            }}
                            loading={isBtnLoading}
                            onClick={() => {
                              setEditQuickBookConnection(false);
                            }}
                          >
                            Cancel
                          </LoadingButton>
                        )}
                        <LoadingButton
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          disabled={(!isQBExists || editQuickBookConnection)}
                          style={{
                            maxWidth: '80px',
                            minWidth: '80px',
                            maxHeight: '40px',
                            minHeight: '40px',
                            marginLeft: 10,
                          }}
                          loading={isBtnLoading}
                          onClick={() => handleClick(activeStep === 0 ? 1 : 'next')}
                        >
                          Next
                        </LoadingButton>
                      </>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Stack>
      </Card>
    </Page>
  );
}
