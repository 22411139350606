// ----------------------------------------------------------------------

const account = {
  displayName: 'Arkan',
  email: 'demo@minimals.cc',
  photoURL: '/assets/images/avatars/avatar_default.jpg',
};

const menuData = [
  {
    title: 'Company',
    menu: [
      { name: 'Switch Company', icon: 'mdi:domain-switch', path: '/select-company' },
      { name: 'Manage Company', icon: 'mdi:office-building-settings-outline', path: '/app/company-list' },
      { name: 'Manage Admin', icon: 'clarity:administrator-solid', path: '/app/manage-admin' },
    ],
  },
  {
    title: 'Configuration',
    menu: [
      { name: 'Bill Mapping', icon: 'tdesign:file-setting', path: '/app/bill-config-list' },
      { name: 'Purchase Order Mapping', icon: 'tdesign:file-setting', path: '/app/purchase-order-config-list' },
      { name: 'Vendor Mapping', icon: 'tdesign:user-setting', path: '/app/vendor-maping' },
      { name: 'Mapping and Configuration', icon: 'ant-design:setting-outlined', path: '/quickbooks/quickbooks_code' },
    ],
  },
];

export {account, menuData};
