import { useEffect } from 'react';
import { Navigate, useRoutes, useNavigate } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SyncBills from './pages/SyncBills';
import LoginPage from './pages/LoginPage';
import ConfigurationPage from './pages/Configuration';
import BillConfigList from './pages/BillConfigList';
import BillDetail from './pages/BillDetail';
import BillFieldMapping from './pages/BillFieldMapping';
import DocuwareConnect from './pages/DocuwareConnect';
import CompanyList from './pages/CompanyList';
import CompanySetup from './pages/CompanySetup';
import CompanyRights from './pages/CompanyRights';
import VendorMaping from './pages/VendorMaping';
import SavedVendorMaping from './pages/SavedVendorMaping';
import TransactionHistory from './pages/TransactionHistory';
import SelectCompany from './pages/SelectCompany';
import Logout from './pages/Logout';
import IgnoredTransactions from './pages/IgnoredTransactions';
import ManageAdmin from './pages/ManageAdmin';
import PurchaseOrderList from './pages/PurchaseOrderList';
import PurchaseOrderMapping from './pages/PurchaseOrderMapping';
import PoTransactions from './pages/POTransactions';
import PurchaseOrderDetails from './pages/PurchaseOrderDetails';

// ----------------------------------------------------------------------

export default function Router() {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  useEffect(() => {
    if (token === null) {
      navigate('/');
    }
  }, [navigate, token]);

  const routes = useRoutes([
    {
      path: '/app',
      element: <DashboardLayout />,
      children: [
        { path: 'docuware-connect', element: <DocuwareConnect /> },
        { path: 'company-list', element: <CompanyList /> },
        { path: 'company-setup', element: <CompanySetup /> },
        { path: 'sync-bill', element: <SyncBills /> },
        { path: 'po-transactions', element: <PoTransactions /> },
        { path: 'config', element: <ConfigurationPage /> },
        { path: 'bill-config-list', element: <BillConfigList /> },
        { path: 'purchase-order-config-list', element: <PurchaseOrderList /> },
        { path: 'purchase-order-mapping', element: <PurchaseOrderMapping /> },
        { path: 'bill-maping', element: <BillFieldMapping /> },
        { path: 'manage-admin', element: <ManageAdmin /> },
        { path: 'company-rights/:companyId/admins', element: <CompanyRights /> },
        { path: 'company-rights/:companyId', element: <CompanyRights /> },
        { path: 'bill-maping/:id/:cabinet', element: <BillFieldMapping /> },
        { path: 'purchase-order-maping/:id/:cabinet', element: <PurchaseOrderMapping /> },
        { path: 'bill-info/:billId', element: <BillDetail /> },
        { path: 'po-transactions-details/:poId', element: <PurchaseOrderDetails /> },
        { path: 'vendor-maping', element: <VendorMaping /> },
        { path: 'save-vendor-maping', element: <SavedVendorMaping /> },
        { path: 'transaction-history', element: <TransactionHistory /> },
        { path: 'transaction-ignored', element: <IgnoredTransactions /> },
        { path: 'logout', element: <Logout /> },
      ],
    },
    {
      path: '/quickbooks',
      element: <DashboardLayout />,
      children: [{ path: 'quickbooks_code', element: <DocuwareConnect /> }],
    },
    {
      path: '/',
      element: <LoginPage />,
    },
    {
      path: '/select-company',
      element: <SelectCompany />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
