import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import { filter } from 'lodash';

import {
  Stack,
  Paper,
  Modal,
  CardHeader,
  TextField,
  Autocomplete,
  IconButton,
  Tooltip,
  Typography,
  Container,
  Grid,
  Checkbox,
  Fade,
  Card,
  CardActions,
  Button,
  FormControlLabel,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  TablePagination,
  styled,
  Badge,
} from '@mui/material';

import LinearProgress from '@mui/material/LinearProgress';
import { ListHeads } from '../sections/@dashboard/user';
import Scrollbar from '../components/scrollbar';
import Page from '../components/Page';
import API_URL from '../global';
import {
  fetchQuickBooksGLAccounts,
  fetchQuickBooksVendors,
  fetchSavedVendorMaping,
  updateVendorLineMaping,
  fetchQuickBooksItems,
} from '../services/api';
import Breadcrumbs from '../components/Breadcrumbs';

const TABLE_HEAD = [
  { id: 'docuwareVendor', label: 'Docuware Vendor', alignRight: false },
  { id: 'qbVendor', label: 'QuickBooks Vendor', alignRight: false },
  { id: 'qbGl', label: 'QuickBooks GL', alignRight: false },
  { id: '', label: '', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const NewAutocomplete = styled(Autocomplete)(() => ({
  '& .MuiOutlinedInput-root': {
    padding: '4px',
  },
  '& .css-1ifcsjq-MuiFormLabel-root-MuiInputLabel-root': {
    fontSize: 'small',
  },
  '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input': {
    fontSize: 'small',
  },
}));

export default function SavedVendorMaping() {
  useEffect(() => {
    const configId = localStorage.getItem('configId');
    const localCompany = localStorage.getItem('qbCompany');
    const qbCompanyId = localCompany ? JSON.parse(localCompany).id : null;

    setConfig(configId);
    setIsTableLoading(true);
    setQbVendorLoading(true);
    setQbGLLoading(true);
    setQbItemLoading(true);
    fetchSavedVendorMaping(configId, qbCompanyId).then((response) => {
      setIsTableLoading(false);
      prepareVendorMapingList(response.data.vendor_list || []);
    });
    fetchQuickBooksVendors(configId).then((response) => {
      if (response.data && response.data.status_code && response.data.status_code === 401) {
        console.log('Token Expired');
      }
      if (response.data.vendor_list) {
        prepareQBVendorList(response.data.vendor_list);
        setQbVendorLoading(false);
      } else {
        console.log('Could not fetch Vendor From QuickBooks');
      }
    });
    fetchQuickBooksGLAccounts(configId)
      .then((response) => {
        if (response.data && response.data.status_code && response.data.status_code === 401) {
          console.log('Token Expired');
        }
        if (response.data.gl_list) {
          prepareGLList(response.data.gl_list);
          setQbGLLoading(false);
        } else {
          console.log('Could not fetch Vendor From QuickBooks');
        }
      })
      .catch(() => {
        setQbGLLoading(false);
      });
    fetchQuickBooksItems(configId)
      .then((response) => {
        let itemList = [];
        if (response.data && response.data.item_list) {
          itemList = prepareItemList(response.data.item_list);
        }
        setQbItemList(itemList);
        setQbItemLoading(false);
      })
      .catch(() => {
        setQbItemLoading(false);
      });
  }, []);

  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isTableLoading, setIsTableLoading] = useState(false);

  const [vendorLineList, setVendorLineList] = useState([]);
  const [config, setConfig] = useState(false);

  const [vendorMapingList, setVendorMapingList] = useState([]);

  const [qbVendorLoading, setQbVendorLoading] = useState(false);
  const [vendorList, setVendorList] = useState([]);

  const [qbItemLoading, setQbItemLoading] = useState(false);
  const [qbItemList, setQbItemList] = useState([]);

  const [qbGLLoading, setQbGLLoading] = useState(false);
  const [qbGLList, setQbGLList] = useState([]);

  const filteredVendors = applySortFilter(vendorMapingList, getComparator(order, orderBy), '');
  const isNotFound = !filteredVendors.length;

  const handleLineSaveClick = () => {
    updateVendorLineMaping(vendorLineList).then((response) => {
      if (response.data.status === 'success') {
        alert('Lines Updated');
        const configId = localStorage.getItem('configId');
        const localCompany = localStorage.getItem('qbCompany');
        const qbCompanyId = localCompany ? JSON.parse(localCompany).id : null;
        fetchSavedVendorMaping(configId, qbCompanyId).then((response) => {
          setIsTableLoading(false);
          prepareVendorMapingList(response.data.vendor_list || []);
        });
        handleClose();
      } else {
        alert('Error while updating Line');
        handleClose();
      }
    });
  };

  const handleLineCellValueChange = (_event, newValue, rowId, field) => {
    if (field === 'item') {
      const updatedRows = (itemList) =>
        itemList.map((row) => (row.rowId === rowId ? { ...row, itemCode: newValue.id, categoryCode: null } : row));
      const updatedItemList = updatedRows(vendorLineList);
      setVendorLineList(updatedItemList);
    } else if (field === 'category') {
      const updatedRows = (itemList) =>
        itemList.map((row) => (row.rowId === rowId ? { ...row, categoryCode: newValue.id, itemCode: null } : row));
      const updatedItemList = updatedRows(vendorLineList);
      setVendorLineList(updatedItemList);
    }
  };

  const prepareItemList = (items) => {
    const itemOptions = items
      ? items.map((item) => {
          const itemVals = {};
          itemVals.id = item.id;
          itemVals.label = item.name;
          return itemVals;
        })
      : [];
    return itemOptions;
  };

  const prepareQBVendorList = (data) => {
    const vendorOptions = data
      ? data.map((item) => {
          const qbVendorItem = {};
          qbVendorItem.id = item.name;
          qbVendorItem.label = item.name;
          return qbVendorItem;
        })
      : [];
    setVendorList(vendorOptions);
  };

  const prepareGLList = (data) => {
    const glOptions = data
      ? data.map((item) => {
          const qbGLItem = {};
          qbGLItem.id = item.name;
          qbGLItem.label = item.name;
          return qbGLItem;
        })
      : [];
    setQbGLList(glOptions);
  };

  const prepareVendorMapingList = (vendorList) => {
    const vendorMapingList = vendorList
      ? vendorList.map((vendor, i) => {
          const vendorItem = {};
          vendorItem.isSaved = false;
          vendorItem.rowId = i + 1;
          vendorItem.docuwareVendor = vendor.docuware_vendor_name;
          vendorItem.qbVendor = vendor.qb_vendor_name;
          vendorItem.qbGl = vendor.gl_account;
          vendorItem.lines = vendor.lines;
          return vendorItem;
        })
      : [];
    setVendorMapingList(vendorMapingList);
  };

  const prepareVendorLineList = (lines) => {
    const vendorMapingList = lines
      ? lines.map((line) => {
          const vendorItem = {};
          vendorItem.rowId = line.id;
          vendorItem.isCategory = line.category_name !== null && line.category_name !== undefined;
          vendorItem.categoryCode = line.category_name;
          vendorItem.isItem = line.item_name !== null && line.item_name !== undefined;
          vendorItem.itemCode = line.item_name;
          vendorItem.lineName = line.docuware_line_name || null;
          return vendorItem;
        })
      : [];
    setVendorLineList(vendorMapingList.filter((vendorItem) => vendorItem.lineName !== null));
  };

  const saveVendorMaping = async (data) => {
    const vendorData = {
      docuware_vendor_name: data.docuwareVendorName,
      qb_vendor_name: data.qbVendorName,
      gl_account: data.glAccount,
      docu_qb_config: data.configId,
      created_by: localStorage.getItem('userId'),
      updated_by: localStorage.getItem('userId'),
    };
    try {
      return await axios.post(`${API_URL}/docuware/api/create-vendor-maping/`, vendorData, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      });
    } catch (err) {
      return err;
    }
  };

  const handleClose = () => setOpen(false);

  const handleOpen = (dwVendorName, qbVendorName, rowId, lines) => {
    setOpen(true);
    prepareVendorLineList(lines);
  };

  const handleCheckBoxChange = (event, rowId, field) => {
    const isChecked = event.target.checked;
    if (field === 'item') {
      const updateLineList = (lineList) =>
        lineList.map((row) =>
          row.rowId === rowId ? { ...row, isItem: isChecked, isCategory: false, categoryCode: false } : row
        );
      const updatedLineList = updateLineList(vendorLineList);
      setVendorLineList(updatedLineList);
    } else if (field === 'category') {
      const updateLineList = (lineList) =>
        lineList.map((row) =>
          row.rowId === rowId ? { ...row, isCategory: isChecked, isItem: false, itemCode: false } : row
        );
      const updatedLineList = updateLineList(vendorLineList);
      setVendorLineList(updatedLineList);
    }
  };

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - vendorMapingList.length) : 0;

  const fetchRowValue = (rowId, updatedList) => {
    const foundRow = updatedList.find((row) => row.rowId === rowId);
    return foundRow || false;
  };

  const saveVendorIfRowLoaded = (row, config, rows) => {
    if (row.qbVendor && row.qbGl) {
      const vendorData = {
        docuwareVendorName: row.docuwareVendor,
        qbVendorName: row.qbVendor.id || row.qbVendor,
        glAccount: row.qbGl.id || row.qbGl,
        configId: config,
      };
      saveVendorMaping(vendorData).then((response) => {
        if (response.status === 200) {
          const updatedRows = (rows) =>
            rows.map((rowItem) => (rowItem.rowId === row.rowId ? { ...rowItem, isSaved: true } : rowItem));
          const updatedList = updatedRows(rows);
          setVendorMapingList(updatedList);
        }
        return true;
      });
    }
    // TODO : in else show error message at line level
    return false;
  };

  const handleCellValueChange = (event, fieldName, newValue, rowId) => {
    if (fieldName === 'qbVendor') {
      const updatedRows = (vendorMapingList) =>
        vendorMapingList.map((row) => (row.rowId === rowId ? { ...row, qbVendor: newValue } : row));
      const updatedList = updatedRows(vendorMapingList);
      setVendorMapingList(updatedList);
      const rowData = fetchRowValue(rowId, updatedList);
      const isSaved = saveVendorIfRowLoaded(rowData, config, updatedList);
      console.log('---row data is as follows :', isSaved);
    } else if (fieldName === 'qbGL') {
      const updatedRows = (vendorMapingList) =>
        vendorMapingList.map((row) => (row.rowId === rowId ? { ...row, qbGl: newValue } : row));
      const updatedList = updatedRows(vendorMapingList);
      setVendorMapingList(updatedList);
      const rowData = fetchRowValue(rowId, updatedList);
      const isSaved = saveVendorIfRowLoaded(rowData, config, updatedList);
      console.log('---row gl data is as follows :', isSaved);
    }
  };
  const navigates = [
    { path: '/app/vendor-maping', name: 'Vendor Mapping' },
    { path: '', name: 'Saved Vendors' },
  ];

  return (
    <Page title="DocuSync To QB">
      <Card variant="outlined" sx={{ mt: 0 }} style={{ borderRadius: 5 }}>
        <Breadcrumbs separator=">" navigates={navigates} />
        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" sx={{ mb: 1, mt: 1 }}>
          <Container style={{ maxWidth: '100%' }}>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <ListHeads
                    order={order}
                    orderBy={orderBy}
                    style={{ marginBottom: 25 }}
                    headLabel={TABLE_HEAD}
                    rowCount={vendorMapingList.length}
                    onRequestSort={handleRequestSort}
                  />
                  {isTableLoading ? (
                    <TableRow>
                      <TableCell align="center" colSpan={7}>
                        <LinearProgress />
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableBody>
                      {filteredVendors.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        const { rowId, isSaved, docuwareVendor, qbVendor, qbGl, lines } = row;

                        return (
                          <TableRow
                            style={{
                              height: 3,
                              marginBottom: 23,
                            }}
                            hover
                            key={rowId}
                            tabIndex={-1}
                            role="checkbox"
                          >
                            <TableCell component="th" scope="row" style={{ paddingTop: 10, paddingBottom: 5 }}>
                              <Typography variant="subtitle2" noWrap>
                                <Grid container spacing={1}>
                                  <Grid item sx={6} md={6}>
                                    {docuwareVendor}
                                  </Grid>
                                  <Grid item sx={2} md={2}>
                                    {isSaved === true && (
                                      <Icon
                                        style={{ color: 'green', height: '25px', width: '25px' }}
                                        icon={'iconamoon:cloud-yes-light'}
                                      />
                                    )}
                                  </Grid>
                                </Grid>
                              </Typography>
                            </TableCell>

                            <TableCell align="left" style={{ paddingTop: 10, paddingBottom: 5 }}>
                              <NewAutocomplete
                                loading={qbVendorLoading}
                                id="combo-box-demo"
                                options={vendorList}
                                disabled={false}
                                size={'small'}
                                value={qbVendor || null}
                                defaultValue={null}
                                onChange={(event, newValue) => {
                                  handleCellValueChange(event, 'qbVendor', newValue, rowId);
                                }}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderInput={(params) => <TextField {...params} placeholder={'Select QB Vendor'} />}
                              />
                            </TableCell>

                            <TableCell align="left" style={{ paddingTop: 10, paddingBottom: 5 }}>
                              <NewAutocomplete
                                loading={qbGLLoading}
                                id="combo-box-demo"
                                options={qbGLList}
                                disabled={false}
                                size={'small'}
                                value={qbGl || null}
                                defaultValue={null}
                                onChange={(event, newValue) => {
                                  handleCellValueChange(event, 'qbGL', newValue, rowId);
                                }}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderInput={(params) => <TextField {...params} placeholder={'Select QB GL Account'} />}
                              />
                            </TableCell>
                            <TableCell align="center" style={{ paddingTop: 10, paddingBottom: 5 }}>
                              <IconButton
                                onClick={() => {
                                  handleOpen(docuwareVendor, qbVendor.id, rowId, lines);
                                }}
                              >
                                <Tooltip title="Line Item Maping" arrow placement="right-end">
                                  <Badge badgeContent={lines.length} color="primary">
                                    <Icon
                                      style={{ color: 'grey', height: '25px', width: '25px' }}
                                      icon={'ion:list-outline'}
                                    />
                                  </Badge>
                                </Tooltip>
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  )}

                  {isNotFound && !isTableLoading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              {!isTableLoading && (
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={vendorMapingList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </Scrollbar>
          </Container>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Fade in={open}>
              <Card variant="outlined" sx={{ mt: 0, width: '75%', borderRadius: 3 }}>
                <CardHeader title="Line Maping" />
                <TableContainer
                  component={Paper}
                  sx={{
                    marginTop: 5,
                    marginBottom: 23,
                    paddingLeft: 2,
                    paddingRight: 2,
                  }}
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Docuware Item Name</TableCell>
                        <TableCell align="left">Is It Item or Category ?</TableCell>
                        <TableCell align="left">Select for QuickBooks</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {vendorLineList.map((row) => (
                        <TableRow key={row.rowId}>
                          <TableCell align="left">{row.lineName}</TableCell>
                          <TableCell align="left">
                            {row.isItem}
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={row.isItem}
                                  onChange={(e) => {
                                    handleCheckBoxChange(e, row.rowId, 'item');
                                  }}
                                  name="jason"
                                />
                              }
                              label="Item"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={row.isCategory}
                                  onChange={(e) => {
                                    handleCheckBoxChange(e, row.rowId, 'category');
                                  }}
                                  name="jason"
                                />
                              }
                              label="GL Account"
                            />
                          </TableCell>
                          {row.isItem && (
                            <TableCell align="left">
                              <NewAutocomplete
                                disablePortal
                                loading={qbItemLoading}
                                id="combo-box-demo"
                                options={qbItemList}
                                disabled={false}
                                size={'small'}
                                value={row.itemCode || null}
                                defaultValue={null}
                                onChange={(event, newValue) => {
                                  handleLineCellValueChange(event, newValue, row.rowId, 'item');
                                }}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderInput={(params) => <TextField {...params} label={'Select QB Item'} />}
                              />
                            </TableCell>
                          )}
                          {row.isCategory && (
                            <TableCell align="left">
                              <NewAutocomplete
                                disablePortal
                                loading={qbGLLoading}
                                id="combo-box-demo"
                                options={qbGLList}
                                disabled={false}
                                size={'small'}
                                value={row.categoryCode || null}
                                defaultValue={null}
                                onChange={(event, newValue) => {
                                  handleLineCellValueChange(event, newValue, row.rowId, 'category');
                                }}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderInput={(params) => <TextField {...params} label={'Select QB GL'} />}
                              />
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <div
                  style={{
                    marginLeft: 15,
                    marginRight: 10,
                    marginBottom: 5,
                  }}
                >
                  <CardActions>
                    <Button variant="contained" color="primary" onClick={handleLineSaveClick}>
                      Save
                    </Button>
                    <Button variant="contained" color="secondary" onClick={handleClose}>
                      Cancel
                    </Button>
                  </CardActions>
                </div>
              </Card>
            </Fade>
          </Modal>
        </Stack>
      </Card>
    </Page>
  );
}
