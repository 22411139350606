export const docuwareFieldList = [
    {
      Name: 'Customer Orders',
      Id: '9babc62d-3599-483d-927e-6d3ceb5a2071',
    },
    {
      Name: 'Document Type',
      Id: 'fa3ae354-9f56-453e-903c-434df5bcd3ca',
    },
    {
      Name: 'Invoice Number',
      Id: '2bdc3d7b-d52e-48b6-9035-8c9da1df1d8d',
    },
    {
      Name: 'Vendor',
      Id: 'ea8c9aea-828c-4faa-aa89-b1b420ba2e18',
    },
    {
      Name: 'Invoice Date',
      Id: '6e006cb4-8ef1-450a-9174-1de64c1f7a7e',
    },
    {
      Name: 'Status',
      Id: 'b_7ed7c0e3-154b-42f7-828e-694e7242c211',
    },
    {
      Name: 'Amount',
      Id: '6e006cb4-8ef1-450a-9174-1de64c1f7a7e',
    },
    {
      Name: 'PO Number',
      Id: 'b_7ed7c0e3-154b-42f7-828e-694e7242c211',
    },
  ];