const navConfig = [
  {
    title: '',
    path: '/select-company',
    icon: 'uil:user',
  },
  {
    title: 'Transaction Sync',
    path: '/app/sync-bill',
    icon: "icon-park-outline:transaction",
  },
  {
    title: 'Transaction History',
    path: '/app/transaction-history',
    icon: "material-symbols:history",
  },
  {
    title: 'PO Transactions',
    path: '/app/po-transactions',
    icon: "icon-park-outline:transaction",
  },
];

export default navConfig;
