import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';

// material
import { Link, Stack, TextField, IconButton, InputAdornment, Typography, Container, Card } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import Page from '../../../components/Page';
import logo from '../../../images/docusync_logo-straight.png';
import { authLogin, getCompanyOfUser } from '../../../services/api';
// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      setIsBtnLoading(true);
      const { username } = formik.values;
      const { password } = formik.values;

      authLogin({ username, password })
        .then((response) => {
          if (response.status === 200) {
            const userId = (response.data.user && response.data.user.id) || null;
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('isAdmin', response.data.is_admin_user || false);
            localStorage.setItem('userId', userId);
            localStorage.setItem('userName', (response.data.user && response.data.user.username) || null);
            localStorage.setItem('userEmail', (response.data.user && response.data.user.email) || null);

            navigate('/select-company', { replace: false });
          } else {
            setIsBtnLoading(false);
            alert('Unable to Login');
          }
        })
        .catch((err) => {
          if (err.response.status === 400) {
            setIsBtnLoading(false);
            alert('Invalid Login');
          }
        });
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <Page title="DocuSync: Login">
      <Card variant="outlined" sx={{ mt: 10 }} style={{ borderRadius: 5 }}>
        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" sx={{ mb: 2, mt: 5 }}>
          <Container>
          <img style={{ width: 300, position: 'relative', left: '20%' }} src={logo} alt='docuSync_Logo_profile'/>
            <Typography variant="h5" sx={{ mb: 4 }}>
              Login
            </Typography>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={2}>
                  <TextField
                    fullWidth
                    autoComplete="username"
                    size="small"
                    type="text"
                    label="Username"
                    {...getFieldProps('username')}
                    error={Boolean(touched.username && errors.username)}
                    helperText={touched.username && errors.username}
                  />

                  <TextField
                    fullWidth
                    autoComplete="current-password"
                    size="small"
                    type={showPassword ? 'text' : 'password'}
                    label="Password"
                    {...getFieldProps('password')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowPassword} edge="end">
                            <Icon icon={showPassword ? 'mingcute:eye-2-fill' : 'eva:eye-off-fill'} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                  />
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="end" sx={{ m: 3 }}>
                  {/* <Link component={RouterLink} variant="subtitle2" to="#">
                    Forgot password?
                  </Link> */}
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    style={{ maxWidth: '100px', minWidth: '100px', maxHeight: '40px', minHeight: '40px' }}
                    loading={isBtnLoading}
                  >
                    Login
                  </LoadingButton>
                </Stack>
              </Form>
            </FormikProvider>
          </Container>
        </Stack>
      </Card>
    </Page>
  );
}
