import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const SnackbarToast = ({
  open,
  message,
  handleClose = () => {},
  type = 'success',
  vertical = 'top',
  horizontal = 'right',
}) => (
  <>
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical, horizontal }}
    >
      <Alert variant="filled" severity={type}>
        {message}
      </Alert>
    </Snackbar>
  </>
);

export default SnackbarToast;
