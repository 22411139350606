import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { filter } from 'lodash';

import {
  Stack,
  Paper,
  Typography,
  Container,
  Grid,
  Card,
  Button,
  Autocomplete,
  Table,
  TextField,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  TablePagination,
  Fade,
  Modal,
  InputAdornment,
  IconButton,
  Tooltip,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import LinearProgress from '@mui/material/LinearProgress';
import copy from 'copy-to-clipboard';
import { Icon } from '@iconify/react';

import { ListHeads } from '../sections/@dashboard/user';
import Scrollbar from '../components/scrollbar';
import Page from '../components/Page';
import { getCompanyUsers, getAllUsers, addCompanyUser, createDocuSyncUser } from '../services/api';
import { randomPasswordGenerator } from '../utils/helpers';
import Breadcrumbs from '../components/Breadcrumbs';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function BillConfigList() {
  const TABLE_HEAD = [{ id: 'userName', label: 'Users', alignRight: false }];

  useEffect(() => {
    setIsTableLoading(true);
    refreshAllUsers();
    refreshCompanyUsers();
  }, []);

  const refreshAllUsers = () => {
    getAllUsers().then((res) => {
      if (res.data && res.data !== undefined) {
        const userList = prepareDSUserList(res.data);
        setDocusyncUsers(userList);
        const normalUserList = res.data.filter((item) => item.is_admin === false);
        const adminUserList = res.data.filter((item) => item.is_admin === true);
        setNormalUserList(prepareDSUserList(normalUserList));
        setAdminList(prepareDSUserList(adminUserList));
      }
    });
  };

  const refreshCompanyUsers = () => {
    getCompanyUsers(companyId).then((res) => {
      if (res.data && res.data !== undefined) {
        const userList = prepareUserList(res.data);
        setUserList(userList);
        setIsTableLoading(false);
      }
    });
  };

  const { companyId } = useParams();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [docusyncUsers, setDocusyncUsers] = useState([]);
  const [selectedDSUser, setSelectedDSUser] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [userName, setUserName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [reFetchPassword, setReFetchPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [isPasswordCopied, setIsPasswordCopied] = useState(false);
  const [error, setError] = useState({ email: '', fName: '', lName: '' });
  const [openAddModal, setOpenAddModal] = useState(false);
  const [isAdminPage, setIsAdminPage] = useState(false);

  const [adminList, setAdminList] = useState([]);
  const [normalUserList, setNormalUserList] = useState([]);


  useEffect(() => {
    if (reFetchPassword) {
      setPassword(randomPasswordGenerator());
      setReFetchPassword(false);
    }
  }, [reFetchPassword]);

  useEffect(() => {
    setPassword(randomPasswordGenerator());
  }, [openModal]);

  const filteredUserList = applySortFilter(userList, getComparator(order, orderBy), '');
  const isNotFound = !filteredUserList.length;

  const prepareUserList = (data) => {
    const userItems = data
      ? data.map((item) => {
          const userItem = {};
          userItem.id = item.id;
          userItem.name = item.username;
          return userItem;
        })
      : [];
    return userItems;
  };

  const prepareDSUserList = (data) => {
    const userItems = data
      ? data.map((item) => {
          const userItem = {};
          userItem.value = item.id;
          userItem.label = item.username;
          return userItem;
        })
      : [];
    return userItems;
  };

  const handleAddUser = () => {
    if (selectedDSUser !== undefined && companyId !== undefined) {
      addCompanyUser({ companyId, userId: selectedDSUser.value }).then((res) => {
        if (res.status === 200) {
          if (res.data.status === 'user already exists') {
            alert('User already exists');
          } else if (res.data.status === 'success') {
            alert('Added User successfully');
          }
          refreshCompanyUsers();
        }
      });
    }
    setOpenModal(false);
    setOpenAddModal(false);
  };

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };
  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleShow = () => {
    setShowPassword(!showPassword);
  };

  const handleCancel = () => {
    setIsAdminPage(false);
    setOpenAddModal(false);
    setOpenModal(false);
    setShowPassword(false);
  };

  const handleCreate = () => {
    if (userName === '' || firstName === '' || lastName === '') {
      setError({
        email: userName === '' ? 'User Name Required' : '',
        fName: firstName === '' ? 'First Name Required' : '',
        lName: lastName === '' ? 'Last Name Required' : '',
      });
    } else {
      setError({ email: '', fName: '', lName: '' });
      createDocuSyncUser({ first_name: firstName, last_name: lastName , username: userName, email: userName, password, is_admin: isAdminPage }).then(() => {
        // TODO : Show message here that user created
        refreshAllUsers();
      });
      setOpenModal(false);
      setShowPassword(false);
    }
  };

  const copyPassword = () => {
    copy(password);
    setIsPasswordCopied(true);
    setTimeout(() => setIsPasswordCopied(false), 2000);
  };

  const handleAdd = (value) => {
    setIsAdminPage(value === 'Admin');
    setOpenAddModal(true);
    setOpenModal(true);
  };
  const navigates = [
    { path: '/app/company-list', name: 'Companies' },
    { path: '', name: 'Manage Company' },
  ];

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;
  const company = localStorage.getItem('manageCompany');

  return (
    <Page title="DocuSync To QB">
      <Card variant="outlined" sx={{ mt: 0 }} style={{ borderRadius: 5 }}>
        <Breadcrumbs separator=">" navigates={navigates} />
        <Typography variant="h5" style={{ margin: 10, marginLeft: 25 }}>
          {company}'s Users
        </Typography>
        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" sx={{ mb: 1, mt: 1, ml: -3, mr: -3 }}>
          <Container style={{ maxWidth: '100%' }}>
            <Stack style={{ marginTop: 10, marginBottom: 15, marginLeft: 25, width: 900 }} spacing={2}>
              <Grid container spacing={2}>
                <Grid item sx={12} md={12}>
                  <Button
                    style={{ marginTop: 15 }}
                    size="small"
                    color="inherit"
                    variant="outlined"
                    sx={{ borderColor: 'grey' }}
                    onClick={() => handleAdd('Admin')}
                  >
                    <Icon style={{ color: '#2E3192', height: '20px', width: '18px' }} icon="ri:admin-line" />
                    <span style={{ fontSize: 15, marginLeft: 5 }}>Assign Admin</span>
                  </Button>
                  <Button
                    style={{ marginTop: 15, marginLeft: 10 }}
                    size="small"
                    color="inherit"
                    variant="outlined"
                    sx={{ borderColor: 'grey' }}
                    onClick={handleAdd}
                  >
                    <Icon style={{ height: '20px', width: '18px' }} icon="mingcute:user-add-2-line" />
                    <span style={{ fontSize: 15, marginLeft: 5 }}>Assign User</span>
                  </Button>
                  <Button
                    style={{ marginTop: 15, marginLeft: 10 }}
                    size="small"
                    color="inherit"
                    variant="outlined"
                    sx={{ borderColor: 'grey' }}
                    onClick={() => setOpenModal(true)}
                  >
                    <Icon style={{ color: '#2E3192', height: '20px', width: '18px' }} icon="icon-park:add-user" />
                    <span style={{ fontSize: 15, marginLeft: 5 }}>Create User</span>
                  </Button>
                </Grid>
              </Grid>
            </Stack>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <ListHeads
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={userList.length}
                    onRequestSort={handleRequestSort}
                  />
                  {isTableLoading ? (
                    <TableRow>
                      <TableCell align="center" colSpan={7}>
                        <LinearProgress />
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableBody>
                      {filteredUserList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        const { name } = row;

                        return (
                          <TableRow
                            style={{ height: 3, overflow: 'visible' }}
                            hover
                            key={name}
                            tabIndex={-1}
                            role="checkbox"
                          >
                            <TableCell component="th" scope="row">
                              <Typography variant="subtitle2" noWrap>
                                <Grid item sx={6} md={6}>
                                  {name}
                                </Grid>
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  )}

                  {isNotFound && !isTableLoading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              {!isTableLoading && (
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={userList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </Scrollbar>
          </Container>
          {/* ---------Create Modal Start--------- */}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openModal}
            onClose={() => setOpenModal(false)}
            closeAfterTransition
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Fade in={openModal}>
              <Card variant="outlined" sx={{ mt: 0, width: '40%' }} style={{ borderRadius: 5 }}>
                {openAddModal ? (
                  <>
                    <Typography variant="h5" style={{ marginLeft: 20, marginTop: 10, marginBottom: -10 }}>
                      {`Assign New ${isAdminPage ? 'Admin' : 'User'}`}
                    </Typography>
                    <div style={{ margin: 15 }}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={isAdminPage ? adminList : normalUserList}
                        disabled={isTableLoading}
                        size={'small'}
                        value={selectedDSUser || null}
                        defaultValue={null}
                        onChange={(_event, newValue) => {
                          setSelectedDSUser(newValue);
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) => <TextField {...params} label="Select User" />}
                      />
                      <div style={{ display: 'flex', margin: 20, justifyContent: 'left', marginLeft: 10 }}>
                        <LoadingButton
                          fullWidth
                          onClick={handleAddUser}
                          size="large"
                          type="submit"
                          variant="contained"
                          style={{
                            maxWidth: '100px',
                            minWidth: '100px',
                            maxHeight: '40px',
                            minHeight: '40px',
                            marginRight: 10,
                          }}
                          loading={false}
                        >
                          Add
                        </LoadingButton>
                        <LoadingButton
                          onClick={handleCancel}
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          style={{
                            maxWidth: '100px',
                            minWidth: '100px',
                            maxHeight: '40px',
                            minHeight: '40px',
                            backgroundColor: '#D43F3E',
                          }}
                          loading={false}
                        >
                          Cancel
                        </LoadingButton>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <Typography variant="h5" style={{ marginLeft: 20, marginTop: 10, marginBottom: -10 }}>
                      Create New User
                    </Typography>
                    <div style={{ margin: 15 }}>
                      <div style={{ display: 'flex', maxWidth: '100%' }}>
                        <div style={{ margin: 10, marginTop: 25, justifyContent: 'center', width: '50%' }}>
                          <TextField
                            label="First Name"
                            required
                            fullWidth
                            size="small"
                            type="text"
                            value={firstName}
                            style={{ border: error.fName === '' ? '' : '1px solid #D43F3E', borderRadius: 5 }}
                            onChange={(e) => {
                              setFirstName(e.target.value);
                              setError({ ...error, fName: '' });
                            }}
                          />
                          <span style={{ color: '#D43F3E', fontSize: 12 }}>{error.fName}</span>
                        </div>
                        <div style={{ margin: 10, marginTop: 25, justifyContent: 'center', width: '50%' }}>
                          <TextField
                            label="Last Name"
                            required
                            fullWidth
                            size="small"
                            type="text"
                            value={lastName}
                            style={{ border: error.lName === '' ? '' : '1px solid #D43F3E', borderRadius: 5 }}
                            onChange={(e) => {
                              setLastName(e.target.value);
                              setError({ ...error, lName: '' });
                            }}
                          />
                          <span style={{ color: '#D43F3E', fontSize: 12 }}>{error.lName}</span>
                        </div>
                      </div>
                      <div style={{ margin: 10, marginTop: 25, justifyContent: 'center' }}>
                        <TextField
                          label="Email"
                          required
                          fullWidth
                          size="small"
                          type="email"
                          value={userName}
                          style={{ border: error.email === '' ? '' : '1px solid #D43F3E', borderRadius: 5 }}
                          onChange={(e) => {
                            setUserName(e.target.value);
                            setError({ ...error, email: '' });
                          }}
                        />
                        <span style={{ color: '#D43F3E', fontSize: 12 }}>{error.email}</span>
                      </div>
                      <div style={{ display: 'flex', margin: 10, marginTop: 25, justifyContent: 'center' }}>
                        <TextField
                          label="Password"
                          fullWidth
                          size="small"
                          type={showPassword ? 'text' : 'password'}
                          value={password}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton aria-label="toggle password visibility" onClick={handleShow} edge="end">
                                  <Icon icon={showPassword ? 'iconoir:eye' : 'humbleicons:eye-close'} />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <Tooltip title="Refresh Password" placement="top" arrow>
                          <Icon
                            style={{ height: 25, width: 25, margin: 10 }}
                            icon="material-symbols-light:refresh"
                            onClick={() => setReFetchPassword(true)}
                          />
                        </Tooltip>
                        <Tooltip title={isPasswordCopied ? 'Password Copied' : 'Copy Password'} placement="top" arrow>
                          <Icon
                            style={{ height: 25, width: 25, margin: 10 }}
                            icon="material-symbols-light:file-copy-outline"
                            onClick={copyPassword}
                          />
                        </Tooltip>
                      </div>
                      <div style={{ display: 'flex', margin: 20, justifyContent: 'left', marginLeft: 10 }}>
                        <LoadingButton
                          fullWidth
                          onClick={handleCreate}
                          size="large"
                          type="submit"
                          variant="contained"
                          style={{
                            maxWidth: '100px',
                            minWidth: '100px',
                            maxHeight: '40px',
                            minHeight: '40px',
                            marginRight: 10,
                          }}
                          loading={false}
                        >
                          Create
                        </LoadingButton>
                        <LoadingButton
                          onClick={handleCancel}
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          style={{
                            maxWidth: '100px',
                            minWidth: '100px',
                            maxHeight: '40px',
                            minHeight: '40px',
                            backgroundColor: '#D43F3E',
                          }}
                          loading={false}
                        >
                          Cancel
                        </LoadingButton>
                      </div>
                    </div>
                  </>
                )}
              </Card>
            </Fade>
          </Modal>
          {/* --------Create Modal End------ */}
        </Stack>
      </Card>
    </Page>
  );
}
