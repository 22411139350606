import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';

// material
import { Stack, Alert, TextField, IconButton, InputAdornment, Typography, Container, Grid, Card } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import Page from '../components/Page';
import {
  createCompany,
  createQBSettings
} from '../services/api';

// ----------------------------------------------------------------------

export default function CompanySetup() {
  const [userId, setUserId] = useState('');
  const [userGroup, setUserGroup] = useState('');
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  

  useEffect(() => {
    const userId = localStorage.getItem('userId') || null;
    const userCompanyId = localStorage.getItem('userCompanyId') || null;
  }, []);

  const navigate = useNavigate();

  const LoginSchema = Yup.object().shape({
    companyName: Yup.string().required('Company Name is required')
  });

  const formik = useFormik({
    initialValues: {
      companyName: '',
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
        const { companyName } = formik.values;
        console.log(' **** companyName here is :', companyName);
        setIsBtnLoading(true);
        createCompany({name:companyName}).then((response)=>{
          console.log(response);
          if(response.status === 200){
            navigate('/app/company-list');
          }else{
            navigate('/app/company-list');
          }
        });
        setIsBtnLoading(true);
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <Page title="DocuSync: Connect">
      <Card variant="outlined" style={{ borderRadius: 5 }}>
        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" sx={{ mb: 2, mt: 5 }}>
          <Container>
          <Grid container spacing={2} sx={{ mb: 2, ml:3 }}>
            <Typography variant="h5" sx={{ mb: 4 }}>
                Company Setup
            </Typography>
          </Grid>
            <Grid container spacing={2} sx={{ mb: 4, ml:3 }}>
            <FormikProvider value={formik}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={2}>
                      <TextField
                        fullWidth
                        autoComplete="companyName"
                        size="small"
                        type="text"
                        label="Company Name"
                        // disabled={isDocuwareExists}
                        style={{ maxWidth: '400px', minWidth: '400px' }}
                        {...getFieldProps('companyName')}
                        error={Boolean(touched.companyName && errors.companyName)}
                        helperText={touched.companyName && errors.companyName}
                      />
                      
                      <Grid container spacing={0}>
                        <Grid item sx={3} md={3}>
                          <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            // disabled={isDocuwareExists}
                            variant="contained"
                            style={{ maxWidth: '100px', minWidth: '100px', maxHeight: '40px', minHeight: '40px' }}
                            loading={isBtnLoading}
                          >
                            Save
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    </Stack>
                  </Form>
                </FormikProvider>
            </Grid>
          </Container>
        </Stack>
      </Card>
    </Page>
  );
}
