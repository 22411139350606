import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';

// material
import { Stack, Container, Card, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import Page from '../components/Page';
import { getQBCompanyOfUser,getCompanyOfUser } from '../services/api';
// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [companyList, setCompanyList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(false);
  const [companyCount, setCompanyCount] = useState(0);

  const handleCompanyChange = (company) => {
    setSelectedCompany(company);
    console.log('here selected company');
    console.log(company);
    localStorage.setItem('userCompanyId', company.id);
    localStorage.setItem('userCompanyName', company.name);
    localStorage.removeItem('cachePOConfiguration');
    // localStorage.setItem('qbCompany', JSON.stringify(company));
    // localStorage.setItem('qbCompany', JSON.stringify(company));
    navigate('/app/docuware-connect', { replace: true });
  };

  useEffect(() => {
    localStorage.removeItem('configForVMapId');
    localStorage.removeItem('configId');
    localStorage.removeItem('configForVMapLabel');
    localStorage.removeItem('cacheHistoryConfiguration');
    localStorage.removeItem('cacheConfHistoryFileCabinetId');
    localStorage.removeItem('cacheTxnHistoryList');
    localStorage.removeItem('cacheConfigFileCabinetId');
    localStorage.removeItem('cacheTransactionList');
    localStorage.removeItem('cacheSyncConfiguration');
    localStorage.removeItem('cacheSyncPageNo');

    const userId = localStorage.getItem('userId') || null;

    getCompanyOfUser({ userId }).then((res) => {      
      const companyOptions = res.data.companies || null;
      if (Array.isArray(companyOptions) && companyOptions.length > 1) {
        setCompanyCount(companyOptions.length);
        localStorage.setItem('companyCount', companyOptions.length);
      } else if (Array.isArray(companyOptions) && companyOptions.length === 1) {
        // TODO SET QB COMPANY HERE, call api and set qb company for this company
        // localStorage.setItem('qbCompany', JSON.stringify(companyOptions[0]));
        console.log('companyOptions');
        console.log(companyOptions[0]);
        localStorage.setItem('userCompanyId', companyOptions[0].id);
        localStorage.setItem('userCompanyName', companyOptions[0].name);
        navigate('/app/docuware-connect', { replace: true });
      } else if (Array.isArray(companyOptions) && companyOptions.length < 1) {
        alert('No Company assigned to User')
        navigate('/', { replace: true });
      }
      setCompanyList(companyOptions);
    });


    // OLD CODE
    // getQBCompanyOfUser({ userId }).then((response) => {
    //   const companyOptions = response.data.companies || null;
    //   if (Array.isArray(companyOptions) && companyOptions.length > 1) {
    //     setCompanyCount(companyOptions.length);
    //     localStorage.setItem('qbCompanyCount', companyOptions.length);
    //   } else if (Array.isArray(companyOptions) && companyOptions.length === 1) {
    //     localStorage.setItem('qbCompany', JSON.stringify(companyOptions[0]));
    //     navigate('/app/docuware-connect', { replace: true });
    //   } else if (Array.isArray(companyOptions) && companyOptions.length < 1) {
    //     alert('No Company assigned to User')
    //     navigate('/', { replace: true });
    //   }
    //   setCompanyList(companyOptions);
    // });
  }, []);

  return (
    <Page title="DocuSync: Select Company">
      <Card variant="outlined" sx={{ mt: 10, ml: 20, mr: 20 }} style={{ borderRadius: 5 }}>
        <Stack direction="column" alignItems="center" justifyContent="center" sx={{ mt: 5 }}>
          <Container maxWidth="sm" style={{display: 'contents'}}>
            <Typography variant='h5'>Select Company</Typography>
            <List
              sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              {companyList &&
                companyList !== undefined &&
                companyList.map((company, index) => (
                  <ListItemButton
                    key={index}
                    style={{ backgroundColor: 'white', margin: 10, borderRadius: 5, boxShadow: '0px 0px 3px 0px, 0px 0px 0px 0px' }}
                    onClick={() => {
                      handleCompanyChange(company);
                    }}
                  >
                    <ListItemIcon>
                      <Icon icon="heroicons:building-office" />
                    </ListItemIcon>
                    <ListItemText primary={company.name} />
                  </ListItemButton>
                ))}
            </List>
          </Container>
        </Stack>
      </Card>
    </Page>
  );
}
