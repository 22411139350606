import React from 'react';

export default function DocuwareIcon(props) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="135.000000pt"
      height="135.000000pt"
      viewBox="0 0 135.000000 135.000000"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g transform="translate(0.000000,135.000000) scale(0.100000,-0.100000)" fill="#303ab2" stroke="none">
        <path d="M573 1125 c-85 -18 -156 -59 -224 -126 -181 -180 -180 -468 1 -649
          182 -182 468 -182 650 0 181 181 182 469 1 649 -69 69 -139 107 -228 126 -82
          18 -119 18 -200 0z m205 -186 c34 -16 73 -45 88 -62 99 -118 99 -286 0 -404
          -35 -42 -138 -93 -188 -93 l-38 0 0 60 0 60 -60 0 -60 0 0 55 0 55 -55 0 -55
          0 0 65 0 65 55 0 55 0 0 55 0 55 60 0 60 0 0 60 0 60 38 0 c23 0 64 -12 100
          -31z"
        />
        <path d="M648 794 l-3 -59 -57 -3 -58 -3 0 -54 0 -54 58 -3 57 -3 3 -58 3 -58
          32 7 c41 8 80 36 109 77 35 49 33 141 -5 190 -34 45 -62 65 -104 73 l-32 6 -3
          -58z"
        />
      </g>
    </svg>
  );
};